import classNames from 'classnames';
import styles from './FJIconButton.module.less';
import PropTypes from 'prop-types';
import FJLoading from '../FJLoading/FJLoading';

const FJIconButton = props => {
    const {
        onClick,
        className,
        textClassName,
        type = 'default',
        icon,
        text,
        style,
        isIconRight = false,
        isLoading,
        strokeColor = '#686c7e',
        loadingSize = 20,
    } = props;
    const boxCn = classNames(styles.btn, styles[type], className, isLoading && styles.loading_box);
    const textCn = classNames(styles.btn_text, styles[type], textClassName);
    const _onClick = e => {
        if (isLoading) {
            return;
        }
        onClick && onClick(e);
    };

    return (
        <div className={boxCn} onClick={_onClick} style={{ ...style }}>
            {!isIconRight && icon && icon}
            <span className={textCn}>{text}</span>
            {isIconRight && icon && icon}
            {isLoading && (
                <FJLoading
                    className={styles.loading}
                    strokeColor={strokeColor}
                    style={{ width: loadingSize, height: loadingSize }}
                />
            )}
            <div className={styles.gradient_mask} />
        </div>
    );
};
FJIconButton.propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.string,
    icon: PropTypes.node,
    text: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    isIconRight: PropTypes.bool,
    textClassName: PropTypes.string,
    isLoading: PropTypes.bool,
};
export default FJIconButton;
