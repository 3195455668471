import Styles from './SinglePage.module.less';
import SubscribeStepContainer from '../SubscribeStep/SubscribeStepContainer';
import ClientsItem from '../PackagePanel/StaticContent/ClientsItem/ClientsItem';
import FJLocalStore from '../../store/FJLocalStore';
import FJCssSwiper from '../PackagePanel/StaticContent/FJCssSwiper/FJCssSwiper';
import FJJSSwiper from '../PackagePanel/StaticContent/FJJSSwiper/FJJSSwiper';
import FJQuestionBrick from '../QuestionBrick/FJQuestionBrick';
import FJEntrance from '../FJEntrance/FJEntrance';

import PanelButton from '../PackagePanel/StaticContent/PanelButton/PanelButton';
import CreditsContainer from '../CreditsPanel/CreditsContainer';

import { useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FJConfig from '../../config/FJConfig';
import mainAction from '../../reducer/action/mainAction';
import { useINOneTimePayment } from '../../hooks/useINOneTimePayment';
import FJUtil from '../../util/FJUtil';
import useIsMobile from '../../hooks/useIsMobile';

const SinglePage = () => {
    const dispatch = useDispatch();
    let panelName = useSelector(state => state.mainReducer.panelName);
    let displayPanelButton = useSelector(state => state.mainReducer.displayPanelButton);
    let planType = useSelector(state => state.mainReducer.planType);

    const __drawTemplateItem = useCallback((item, index, childAllowClick) => {
        return <ClientsItem key={index} item={item} index={index} childAllowClick={childAllowClick} />;
    }, []);

    const list = useMemo(() => {
        return new Array(5).fill({}).map((item, index) => {
            return {
                desc: FJLocalStore._(`CLIENTS_SAY_${index + 1}_CONTENT`),
                name: FJLocalStore._(`CLIENTS_SAY_${index + 1}_NAME`),
                img: `https://resource.flexclip.com/pages/company/pricing/${index + 1}.webp?v=${FJConfig.buildVersion}`,
            };
        });
    }, []);

    const isInOneTimepayment = useINOneTimePayment();

    const QuestionList = useMemo(() => {
        let result = new Array(panelName === 'Credits' ? 7 : 9).fill({}).map((item, index) => {
            return {
                title: `${panelName.toLowerCase()}-question-${index + 1}`,
                answer: `${panelName.toLowerCase()}-question-${index + 1}-answer`,
            };
        });

        // 在订阅面板却当前用户是印度用户则隐藏faq第四条：我可以随时取消订阅吗？
        if (panelName !== 'Credits' && isInOneTimepayment) {
            result = result.filter((_, index) => index !== 4);
        }
        return result;
    }, [isInOneTimepayment, panelName]);

    useEffect(() => {
        if (!window.fjuser) {
            return;
        }

        let updateFJTier = () => {
            window.FJTier.check(true, () => {
                window.FJTier.addListener(data => {
                    if (!data) return;
                    const obj = {};
                    obj.openDiscount = true;
                    obj.lists = [];
                    // eslint-disable-next-line guard-for-in
                    for (let key in data.list) {
                        obj.lists.push(data.list[key]);
                    }
                    dispatch(mainAction.setDiscountConfAction(obj));
                    window.FJTier.apply();
                });
            });
        };
        let update = () => {
            dispatch(mainAction.changeLoadingStatusAction(true));
            window.FJGlobalariable.modSubscription.network
                .getSubscription()
                .then(response => {
                    window.FJGlobalariable.modSubscription.updateSubscriptionInfo(response.data.subscription);
                    let subscription = response.data.subscription;
                    dispatch(
                        mainAction.setCurrentPlan({
                            package: subscription.package,
                            period: subscription.period,
                            price: subscription.price,
                            period_end: subscription.period_end,
                            status: subscription.status,
                            sub_type: subscription.sub_type ? subscription.sub_type : 'stripe',
                        }),
                    );
                    const teamSubscription = response.data.teamSubscription;
                    if (teamSubscription) {
                        dispatch(mainAction.setTeamSubscription({ ...teamSubscription }));
                    }
                    if (planType === 'team') {
                        dispatch(
                            mainAction.changePayMethodAction(
                                teamSubscription.sub_type && teamSubscription.sub_type !== 'exch'
                                    ? teamSubscription?.sub_type
                                    : 'stripe',
                            ),
                        );
                    } else {
                        dispatch(
                            mainAction.changePayMethodAction(
                                subscription.sub_type && subscription.sub_type !== 'exch'
                                    ? subscription.sub_type
                                    : 'stripe',
                            ),
                        );
                    }
                    // 更新分区定价信息
                    updateFJTier();
                })
                .catch(error => {
                    dispatch(mainAction.changeLoadingStatusAction(false));
                })
                .finally(() => {
                    dispatch(mainAction.changeLoadingStatusAction(false));
                });
        };

        // 展示除支付界面的其他页面内容
        let _displayBaseDom = () => {
            if (document.getElementById('pricing-view-container')) {
                // document.getElementById('pricing-view-container').style.display = 'none';
                document.getElementById('pricing-view-container').style.display = 'block';
            }
            // 将头部的两个切换按钮进行显示、隐藏切换
            dispatch(mainAction.showOrHiddenPanelButton(true));
        };

        if (window.fjuser.eventType) {
            // 监听登录事件
            window.fjuser.addListener(window.fjuser.eventType.login, (result, json) => {
                if (result) {
                    dispatch(mainAction.changeStepAction(1));
                    dispatch(mainAction.changeBuyCreditsSuccessPage(false));
                    dispatch(mainAction.openOrCloseCreditsPayPanel(false));
                    _displayBaseDom();
                    update();
                }
            });
            // 监听退出账号事件
            window.fjuser.addListener(window.fjuser.eventType.logout, (result, json) => {
                if (result) {
                    dispatch(mainAction.changeStepAction(1));
                    dispatch(mainAction.changeBuyCreditsSuccessPage(false));
                    dispatch(mainAction.openOrCloseCreditsPayPanel(false));
                    _displayBaseDom();
                    dispatch(
                        mainAction.setCurrentPlan({
                            package: 'free',
                            period: null,
                            last4: null,
                            price: null,
                            period_end: null,
                            status: null,
                            sub_type: '',
                        }),
                    );
                    dispatch(mainAction.setTeamSubscription(null));

                    // 更新折扣信息
                    dispatch(
                        mainAction.setDiscountConfAction({
                            openDiscount: false,
                            lists: [],
                        }),
                    );
                }
            });
        }
    }, []);
    const isMobile = useIsMobile();
    useEffect(() => {
        if (panelName === 'Subscription' && isMobile) {
            dispatch(mainAction.changeTopPanel('Credits'));
        }
    }, [isMobile, panelName, dispatch]);
    return (
        <div className={Styles.single_box}>
            {displayPanelButton && !isMobile && <PanelButton />}
            {panelName === 'Subscription' && <SubscribeStepContainer />}
            {panelName === 'Credits' && <CreditsContainer />}

            {/* 这个部分只有当是全屏时才进行展示 */}
            {!isMobile && (
                <div id='pricing-view-container'>
                    <div className={Styles.trust_text}>{FJLocalStore._('trusted-by')}</div>
                    <FJCssSwiper boxClass={Styles.cssSwiper} logoBoxClass={Styles.logo_bbox} />
                    <section className={Styles.swiperBox}>
                        <h2 className={Styles.swiperTitle}>{FJLocalStore._('WHAT_OUR_CLIENT_SAY')}</h2>
                        <FJJSSwiper
                            list={list}
                            defaultSlot={__drawTemplateItem}
                            direction='forward'
                            intervalTime={1.5}
                        />
                    </section>
                    <FJQuestionBrick
                        itemClass={Styles.questionItemBox}
                        boxClass={Styles.questionBox}
                        direction='vertical'
                        questionList={QuestionList}
                    />
                    <FJEntrance />
                </div>
            )}
        </div>
    );
};
export default SinglePage;
