// let basePath = '';
const version = '1.9.1.0.6';

const buildVersion = '1.9.1.0.6';
const environment = import.meta.env.VITE_CURRENT_PUBLIC_SERVER_TAG_TYPE;

console.log('subscribeVersion:::', buildVersion);
let configUrl = null;
if (environment === 'local') {
    configUrl = 'flexclip.config.js';
} else if (location.href.indexOf('www.flexclip.com')) {
    configUrl = 'flexclip.config.js';
}

export default {
    version,
    buildVersion,
    serverPath: environment !== 'release' ? 'https://www.smartvideomaker.com' : 'https://www.flexclip.com',
    requestPath: environment !== 'release' ? 'https://www.smartvideomaker.com' : 'https://www.flexclip.com',
    // languagePath: environment === 'local' ? `${basePath}language/local.` : `${basePath}media/login_panel/v1/language/local.`,
    languagePath: environment === 'local' ? `language/local.` : `/app/subscription/language/local.`,
    configUrl: configUrl,
    USAExchangeRate: {
        area_code: 'US',
        currency_code: 'USD',
        currency_symbol: '$',
        rate: 0,
    },
    stripeKey:
        environment !== 'release'
            ? 'pk_test_RsyOnNrwFwAJQ1jXGBbTZb4x000oCEz4cJ'
            : 'pk_live_Vf2kU5PAdEYdKwu3nZJNtkqe001YTrZQen',
    // 使用欧元支付的国家列表
    eurList: [
        'AT',
        'BE',
        'FI',
        'FR',
        'DE',
        'GR',
        'IE',
        'IT',
        'LU',
        'NL',
        'PT',
        'SI',
        'ES',
        'MT',
        'CY',
        'SK',
        'EE',
        'LV',
        'LT',
        'HR',
    ],
};
