import React from 'react';
import FJStripePay from '../view/FJStripePay';
import { useSelector, useDispatch } from 'react-redux';
import FJStripePayAction from '../../../reducer/action/FJStripePayAction';
import { useCallback } from 'react';
import mainAction from '../../../reducer/action/mainAction';
import FJViewConstant from '../../../constant/FJViewConstant';

const FJStripePayContainer = (props, ref) => {
    const dispatch = useDispatch();
    const { mode, boxClassName } = props;
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);

    const stripePay = useCallback((confirmToken, options = {}) => {
        const { type } = options;
        if (type === FJViewConstant.CREDITS) {
            dispatch(FJStripePayAction.stripePayInCreditsAction(confirmToken, options));
            return;
        } else if (type === FJViewConstant.ONE_TIME_PAYMENT) {
            dispatch(FJStripePayAction.stripePayWithOneOffAction(confirmToken, options));
            return;
        }
        dispatch(FJStripePayAction.stripePayAction(confirmToken, options));
    }, []);

    const changeLoadingStatus = useCallback(bool => {
        dispatch(mainAction.changeLoadingStatusAction(bool));
    }, []);

    const loadStripeUIStatus = useCallback(status => {
        dispatch(FJStripePayAction.changeLoadStripeUIStatusAction(status));
    }, []);

    return (
        <FJStripePay
            ref={ref}
            stripePay={stripePay}
            changeLoadingStatus={changeLoadingStatus}
            mode={mode}
            loadStripeUIStatus={loadStripeUIStatus}
            currentCountryMonetaryISO={currentCountryMonetaryISO}
            boxClassName={boxClassName}
        />
    );
};

export default React.forwardRef(FJStripePayContainer);
