import styles from './FJCreditsPurchasePanel.module.less';
import coin from '../../../assets/mobileBase64/coin';
import banner from '../../../assets/mobileBase64/banner';
import FJLocalStore from '../../../store/FJLocalStore';
import FJMobileHeader from '../FJMobileHeader/FJMobileHeader';
import FJMobileFooter from '../FJMobileFooter/FJMobileFooter';
import FJCreditsPackageViewContainer from '../FJCreditsPackageView/FJCreditsPackageView.container';

const TIPS = [
    'one-time-purchase',
    `the-credits-can-be-used-for-any-of-flexclips-ai-features`,
    `valid-for-365-days-from-the-date-of-purchase`,
];

const FJCreditsPurchasePanel = props => {
    const { creditsPayData, creditsNum, gotoBack, payNow } = props;
    const renderMyCreditsInfo = () => {
        return (
            <div className={styles.credits_info}>
                <img src={coin} />
                <div className={styles.title}>{FJLocalStore._('credits-balance')}</div>
                <div className={styles.credits_num}>{creditsNum}</div>
            </div>
        );
    };

    const renderTips = () => {
        return (
            <div className={styles.tip_box}>
                <div className={styles.tips_title}>{FJLocalStore._('description')}</div>
                {TIPS.map((item, index) => {
                    return (
                        <li key={index} className={styles.tip_item}>
                            <div className={styles.order}>{index + 1}</div>
                            <span className={styles.info}>{item}</span>
                        </li>
                    );
                })}
            </div>
        );
    };
    const purchaseInfo = creditsPayData.info;
    const price = purchaseInfo ? purchaseInfo.priceUnit + purchaseInfo.price : '';
    const btnText = FJLocalStore._('pay-now') + ' ' + price;
    return (
        <div className={styles.box} style={{ backgroundImage: `url(${banner})` }}>
            <FJMobileHeader title={FJLocalStore._('credits-center')} gotoBack={gotoBack} />
            {renderMyCreditsInfo()}
            <FJCreditsPackageViewContainer />
            {renderTips()}
            <FJMobileFooter text={btnText} onClick={payNow} />
        </div>
    );
};
export default FJCreditsPurchasePanel;
