import styles from './FJMobileFooter.module.less';
import FJIconButton from '../../common/FJIconButton/FJIconButton';
import classNames from 'classnames';
const FJMobileFooter = props => {
    const { text, footerClassName, btnClassName, btnType = 'gradient', onClick } = props;

    return (
        <div className={classNames(styles.footer, footerClassName)}>
            <FJIconButton
                className={classNames(styles.pay_btn, btnClassName)}
                text={text}
                type={btnType}
                onClick={onClick}
            />
        </div>
    );
};
export default FJMobileFooter;
