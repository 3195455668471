import React, { useRef, useState, Fragment } from 'react';
import Style from './SeatsSelector.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const SeatsSelector = ({ seatsData, initialValue = '', initialSelectedIndex = -1, classname, onChangeSeats }) => {
    const [value, setValue] = useState(initialValue);
    const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex);
    const [isFocus, setIsFocus] = useState(false);
    const lastValue = useRef(initialValue);
    const minSeatCount = useSelector(state => state.mainReducer.minSeatCount);
    const maxSeatCount = useSelector(state => state.mainReducer.maxSeatCount);

    const _onChange = e => {
        if (e.target.value === '') {
            setValue('');
            lastValue.current = '';
        } else if (e.target.validity.valid) {
            const length = Math.max(Math.min(Number(e.target.value), maxSeatCount), 1);
            setValue(`${length}`);
            lastValue.current = `${length}`;
            onChangeSeats(Math.max(minSeatCount, length));
        }
    };

    const _onFocus = () => {
        setIsFocus(true);
        setValue(lastValue.current);
        if (lastValue.current !== '') {
            const length = Math.max(Math.min(Number(lastValue.current), maxSeatCount), minSeatCount);
            onChangeSeats(length);
        }
    };

    const _onBlur = () => {
        setIsFocus(false);

        if (value === '') {
            if (seatsData[selectedIndex]) {
                onChangeSeats(seatsData[selectedIndex].length);
            }
        } else {
            const length = Number(value);
            if (length < minSeatCount) {
                lastValue.current = `${minSeatCount}`;
                setValue(`${minSeatCount}`);
                onChangeSeats(minSeatCount);
            }
        }
    };

    const _onSeatClick = index => {
        setSelectedIndex(index);
        setValue('');
        onChangeSeats(seatsData[index].length);
    };

    let showSelect = true;
    if (isFocus) {
        showSelect = false;
    } else {
        showSelect = value === '';
    }

    return (
        <div className={classNames(Style.seats_selector, classname)}>
            {seatsData.map((seat, index) => (
                <Fragment key={index}>
                    <div
                        className={classNames(Style.seat, {
                            [Style.selected]: index === selectedIndex && showSelect,
                        })}
                        onMouseDown={() => _onSeatClick(index)}
                    >
                        {seat.title}
                    </div>
                    <div className={Style.line}></div>
                </Fragment>
            ))}
            <input
                className={classNames(Style.custom, { [Style.selected]: !showSelect })}
                placeholder={FJLocalStore._('custom')}
                value={value}
                pattern='[0-9]*'
                type='text'
                onChange={_onChange}
                onFocus={_onFocus}
                onBlur={_onBlur}
                size={FJLocalStore._('custom').length}
            />
        </div>
    );
};

export default SeatsSelector;
