import Style from './EditCardInfoPopup.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import Input from '../CreditCardPanel/Input';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../reducer/action/mainAction';
import FJUtil from '../../util/FJUtil';

import CloseSvg from '../../icon/close.svg';

import InlineSvg from 'react-inlinesvg';

let EditCardInfoPopup = props => {
    let { cardParams } = props;

    const planType = useSelector(state => state.mainReducer.planType);
    const teamSubscription = useSelector(state => state.mainReducer.teamSubscription);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [response, setResponse] = useState(null);
    let shouldHideLoading = useRef(true);
    let cardRef = useRef(null);
    let dateRef = useRef(null);
    let cvcRef = useRef(null);
    useEffect(() => {
        setTimeout(() => {
            window.jQuery('#card-change-element [data-stripe="number"]').payment('formatCardNumber');
            window.jQuery('#card-change-element [data-stripe="exp"]').payment('formatCardExpiry');
            window.jQuery('#card-change-element [data-stripe="cvc"]').payment('formatCardCVC');
        }, 0);
        // $('#card-change-element [data-stripe="number"]').payment('formatCardNumber');
    }, []);
    let _cancel = () => {
        dispatch(mainAction.closeEditCardInfoPopup());
    };
    let _cleanErrTip = () => {
        cardRef.current.cleanErr();
        dateRef.current.cleanErr();
        cvcRef.current.cleanErr();
    };
    let _dataValid = card => {
        // 清空所有错误信息
        _cleanErrTip();

        let cardType = window.jQuery.payment.cardType(card.number);
        if (!window.jQuery.payment.validateCardNumber(card.number)) {
            // 信用卡验证失败
            cardRef.current.setErr(FJLocalStore._('INVALID_NUMBER'));
            return false;
        }

        if (
            !window.jQuery.payment.validateCardExpiry(
                window.jQuery('#card-change-element [data-stripe="exp"]').payment('cardExpiryVal'),
            )
        ) {
            // 验证过期时间失败
            dateRef.current.setErr(FJLocalStore._('INVALID_EXP_DATE'));
            return false;
        }

        if (!window.jQuery.payment.validateCardCVC(card.CVC, cardType)) {
            // 验证安全码失败
            cvcRef.current.setErr(FJLocalStore._('INVALID_CVC'));
            return false;
        }
        return true;
    };
    let _submit = () => {
        setResponse(null);
        let number = cardRef.current.getValue();
        let date = dateRef.current.getValue();
        let CVC = cvcRef.current.getValue();
        setLoading(true);
        if (
            _dataValid({
                number,
                date,
                CVC,
            })
        ) {
            dispatch(mainAction.changeLoadingStatusAction(true));
            // FJGlobalariable.modSubscription.showLoading = true;
            // cardParams.teamId 是外部调用修改卡是传入的
            let teamId = null;
            if (cardParams?.teamId) {
                teamId = cardParams.teamId;
            } else if (planType === 'team') {
                teamId = teamSubscription?.id;
            }
            window.FJGlobalariable.modSubscription.network
                .changeCard('#card-change-element', teamId)
                .then(response => {
                    switch (response.code) {
                        case 200:
                            let last4 = response.data.last4;
                            // this.callback(last4);
                            if (cardParams && cardParams.callback) {
                                cardParams.callback(last4);
                            }
                            // this.showModal = false;
                            // setShowModal(false)
                            if (planType === 'team' && teamSubscription) {
                                dispatch(
                                    mainAction.setTeamSubscription({
                                        ...teamSubscription,
                                        last4,
                                    }),
                                );
                            } else {
                                window.fj.currentPlan.last4 = last4;
                                dispatch(mainAction.setCurrentPlan({ last4: last4 }));
                            }
                            dispatch(mainAction.closeEditCardInfoPopup());
                            break;
                        case 214:
                            // this.showModal = false;
                            // setShowModal(false)
                            dispatch(mainAction.closeEditCardInfoPopup());
                            shouldHideLoading.current = true;
                            window.FJGlobalariable.modSubscription.noLoginResponseCallback();
                            FJUtil.checkLogin(response.code);
                            break;
                        case 335:
                            dispatch(mainAction.closeEditCardInfoPopup());
                            shouldHideLoading.current = true;
                            window.FJGlobalariable.modSubscription.noLoginResponseCallback();
                            FJUtil.checkLogin(response.code);
                            break;
                        case 204:
                            // 因为customer被删除而无法切换信用卡的情况
                            // this.showModal = false;
                            // setShowModal(false)
                            dispatch(mainAction.closeEditCardInfoPopup());
                            window.fj.currentPlan.last4 = '';
                            break;
                        default:
                            setResponse(response.msg);
                            // this.errorTexts.response = response.msg;
                            // shouldHideLoading.current = true;
                            dispatch(mainAction.changeLoadingStatusAction(false));
                            break;
                    }
                })
                .catch(error => {
                    switch (error.type) {
                        case 'stripe':
                            // console.log(error.error.message)
                            setResponse(error.error.message);
                            // this.errorTexts.response = error.error.message;
                            break;
                        case 'ajax':
                        default:
                            // console.log('SUB.CONNECT_NETWORK_FAILED')
                            setResponse(FJLocalStore._('CONNECT_NETWORK_FAILED'));
                        // this.errorTexts.response = text_('SUB.CONNECT_NETWORK_FAILED');
                    }
                    // window.FJGlobalariable.modSubscription.showLoading = false;
                    dispatch(mainAction.changeLoadingStatusAction(false));
                })
                .finally(() => {
                    // this.isSubmitting = false;
                    // if (shouldHideLoading.current) {
                    dispatch(mainAction.changeLoadingStatusAction(false));
                    // window.FJGlobalariable.modSubscription.showLoading = false;
                    // }
                });
        } else {
            // this.isSubmitting = false;
        }
    };

    let title = cardParams?.title ?? FJLocalStore._('CARD_FORM_TITLE');

    return (
        <div className={Style.fj_sub_modal_edit} style={{ display: showModal ? 'block' : '' }}>
            <div className={Style.mask}>
                <div
                    className={Style.content}
                    onClick={event => {
                        event.stopPropagation();
                    }}
                >
                    <div className={Style.closeBox} onClick={_cancel}>
                        <InlineSvg src={CloseSvg} />
                    </div>
                    <div className={Style.title}>{title}</div>
                    <form id='card-change-element' className={Style.form}>
                        <div className={Style.input_title}>{FJLocalStore._('PAY_CARD_NUMBER')}</div>
                        <Input ref={cardRef} placeholder={FJLocalStore._('PAY_CARD_NUMBER')} stripe='number' />
                        <div className={Style.time_cvc_box}>
                            <div>
                                <div className={Style.input_title}>{FJLocalStore._('expire-date')}</div>
                                <Input ref={dateRef} placeholder={FJLocalStore._('PAY_CARD_DATE')} stripe='exp' />
                            </div>
                            <div>
                                <div className={Style.input_title}>{FJLocalStore._('PAY_CARD_CVC')}</div>
                                <Input ref={cvcRef} placeholder={FJLocalStore._('PAY_CARD_CVC')} stripe='cvc' />
                            </div>
                        </div>
                    </form>
                    <div className={Style.response_box}>{response}</div>
                    <div className={Style.btn_box}>
                        <div className={Style.cancel_box} onClick={_cancel}>
                            {FJLocalStore._('BUTTON_CANCEL')}
                        </div>
                        <div className={Style.save_box} onClick={_submit}>
                            {FJLocalStore._('BUTTON_SAVE')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EditCardInfoPopup;
