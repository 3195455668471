import PackagePanelContent from './PackagePanelContent';
import { useSelector } from 'react-redux';
// 订阅入口
const PackagePanelContainer = props => {
    let { popupType, changeStep, isAnnually, changePlan, active } = props;
    let { planData, planType, discountConf } = useSelector(state => {
        return {
            planData: state.mainReducer.planData,
            planType: state.mainReducer.planType,
            discountConf: state.mainReducer.discountConf,
        };
    });

    return (
        <PackagePanelContent
            popupType={popupType}
            planData={planData}
            changeStep={changeStep}
            isAnnually={isAnnually}
            changePlan={changePlan}
            planType={planType}
            active={active}
            discountConf={discountConf}
        />
    );
};

export default PackagePanelContainer;
