import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import FJMainReducer from './FJMainReducer';

let allReducer = combineReducers({
    mainReducer: FJMainReducer,
});

const store = createStore(allReducer, applyMiddleware(thunk));

export default store;
