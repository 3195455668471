import mainAction from './mainAction';
import FJUtil from '../../util/FJUtil';
import FJMessageStore from '../../store/FJMessageStore';
import FJLocalStore from '../../store/FJLocalStore';
import FJPayUtil from '../../util/FJPayUtil';
import FJNetworkStore from '../../store/FJNetworkStore';
import FJActionConstant from '../../constant/FJActionConstant';
import FJViewConstant from '../../constant/FJViewConstant';
import FJMobilePayAction from './FJMobilePayAction';
let fTimerInNewWindow = null;
let f_prepaymentId = null;

/**
 * @description 升级使用（失败的处理）
 * @param code
 * @private
 */
const __failWithUpgradeCode = code => {
    switch (code) {
        case 214:
            FJUtil.checkLogin(code);
            window.FJGlobalariable.modSubscription.noLoginResponseCallback();
            break;
        case 335:
            FJUtil.checkLogin(code);
            window.FJGlobalariable.modSubscription.noLoginResponseCallback();
            break;
        default:
            FJMessageStore.addMessage(FJLocalStore._('CONNECT_NETWORK_FAILED'), 'error');
            break;
    }
};

/**
 * @description 创建订阅（失败的处理）
 * @param code
 * @param msg
 * @private
 */
const __failWithCreateCode = (code, msg) => {
    switch (code) {
        case 214:
            FJUtil.checkLogin(code);
            window.FJGlobalariable.modSubscription.noLoginResponseCallback();
            break;
        case 335:
            FJUtil.checkLogin(code);
            break;
        default:
            if (window.fj.currentPlan.package === 'free' && code !== 501) {
                FJMessageStore.addMessage(FJLocalStore._('PLEASE_USE_PAYPAL'), 'error');
            }
    }
};

const __successFn = (dispatch, response, options = {}) => {
    const { selectPage, dataInfo, discount } = options;

    const subscription = { ...response.subscription };
    window.FJGlobalariable.modSubscription.updateSubscriptionInfo(subscription);

    if (selectPage === 'team') {
        dispatch(mainAction.setTeamSubscription(subscription));
        return subscription;
    } else {
        dispatch(
            mainAction.setCurrentPlan({
                package: subscription.package,
                period: subscription.period,
                last4: subscription.last4,
                price: subscription.price,
                period_end: subscription.period_end,
                status: subscription.status,
                sub_type: subscription.sub_type ? subscription.sub_type : 'stripe',
            }),
        );
    }

    window.FJGlobalariable.modSubscription.state.amount = subscription.amount;
    window.FJGlobalariable.modSubscription.state.coupon = dataInfo.appliedCoupon;
    window.FJGlobalariable.modSubscription.state.discount = discount;
    window.FJGlobalariable.modSubscription.state.transaction_id = subscription.transaction_id;

    return subscription;
};
const __successCreateFn = (dispatch, subscription, options = {}) => {
    const { dataInfo, discount } = options;
    dispatch(
        mainAction.setDataInfo({
            coupon: dataInfo.appliedCoupon,
            discount: discount,
            amount: subscription.amount,
            transaction_id: subscription.transaction_id,
            appliedCoupon: null,
        }),
    );

    // 订阅成功关闭折扣码
    let obj = {};
    obj.openDiscount = false;
    dispatch(mainAction.setDiscountConfAction(obj));

    window.FJGlobalariable.modSubscription.state.page = 'successful';
    dispatch(mainAction.changeStepAction(3));
    FJPayUtil.refreshInvoices();
};
const __successUpgradeFn = (dispatch, subscription, options = {}) => {
    const { dataInfo, discount } = options;
    dispatch(
        mainAction.setDataInfo({
            coupon: dataInfo.appliedCoupon,
            discount: discount,
            amount: subscription.amount,
            transaction_id: subscription.transaction_id,
        }),
    );

    window.FJGlobalariable.modSubscription.state.page = 'successful';
    dispatch(mainAction.changeStepAction(3));
    FJPayUtil.refreshInvoices();
};

/**
 * @description 成功订阅事件
 * @param subscription
 * @param options
 * @private
 */
const __successSubscriptStatisticsEvent = (subscription, options = {}) => {
    const { dataInfo, plan, paymentMethod } = options;
    // 统计事件
    if (window.ealog && window.ealog.setUserProperties) {
        let showSimpleTimeline = FJPayUtil.getCookie(window.fjuser.info.id + '_close_simple_timeline');
        let editor_mode = window.fjuser.info.is_simple_timeline
            ? 'simple timeline'
            : showSimpleTimeline
            ? 'storyboard'
            : '';
        window.ealog && window.ealog.setUserProperties({ 'editor mode': editor_mode });
        if (selectPage === 'team') {
            window.ealog.setUserProperties({
                'team type': subscription.package + ' ' + subscription.period,
            });
        } else {
            window.ealog.setUserProperties({
                'account type': subscription.package + ' ' + subscription.period,
            });
        }
    }

    window.ealog &&
        window.ealog.addEvent('revenue', {
            price: subscription.amount / 100,
            pay_type: plan,
            'is coupon code': dataInfo.appliedCoupon ? true : false,
            'coupon code off':
                dataInfo.appliedCoupon && (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
            'coupon code': dataInfo.appliedCoupon,
            platform: 'stripe',
            'stripe method': paymentMethod,
            '10.9 eur-pay ab test': FJUtil.getPayCur(),
        });
    window.gaEvent('subscription_created', window.FJGlobalariable.modSubscription.entry, '');
    window.gaPage('subscription/' + plan + '.html', 'Subscription Created');
};

/**
 * @description 成功升级事件
 * @private
 */
const __successUpgradeStatisticsEvent = (subscription, options = {}) => {
    const { old_plan, dataInfo, plan, paymentMethod } = options;
    if (window.ealog && window.ealog.setUserProperties) {
        let showSimpleTimeline = FJPayUtil.getCookie(window.fjuser.info.id + '_close_simple_timeline');
        let editor_mode = window.fjuser.info.is_simple_timeline
            ? 'simple timeline'
            : showSimpleTimeline
            ? 'storyboard'
            : '';
        window.ealog && window.ealog.setUserProperties({ 'editor mode': editor_mode });
        if (selectPage === 'team') {
            window.ealog.setUserProperties({
                'team type': subscription.package + ' ' + subscription.period,
            });
        } else {
            window.ealog.setUserProperties({
                'account type': subscription.package + ' ' + subscription.period,
            });
        }
    }

    window.ealog &&
        window.ealog.addEvent('revenue', {
            price: subscription.amount / 100,
            pay_type: old_plan + '-' + plan,
            'is coupon code': dataInfo.appliedCoupon ? true : false,
            'coupon code off':
                dataInfo.appliedCoupon && (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
            'coupon code': dataInfo.appliedCoupon,
            platform: 'stripe',
            'stripe method': paymentMethod,
            '10.9 eur-pay ab test': FJUtil.getPayCur(),
        });
    window.gaEvent('subscription_upgrade', window.FJGlobalariable.modSubscription.entry, '');
    window.gaPage('subscription/' + old_plan + '-' + plan + '.html', 'Subscription Upgrade');
};

const __getPlanDetail = (reducePlanData, selectPage) => {
    let planDetail;
    reducePlanData.forEach(item => {
        if (item.package === selectPage) {
            planDetail = item;
        }
    });
    return planDetail;
};

const __isUpgrade = (planType, teamSubscription, currentPlan) => {
    if (planType === 'team') {
        return teamSubscription && teamSubscription.package !== 'free';
    }
    return currentPlan.package !== 'free' && currentPlan.sub_type !== 'exch' && currentPlan.status !== 'promotion';
};

const __getSelectPeriod = isAnnually => {
    return isAnnually ? 'annual' : 'monthly';
};

const __getDiscount = (isAnnually, planDetail, dataInfo) => {
    const { price } = planDetail;
    const selectPeriod = __getSelectPeriod(isAnnually);
    const _totalPrice = () => {
        if (selectPeriod === 'monthly') return price.monthly.toFixed(2);
        else {
            return (price.annual * 12).toFixed(2);
        }
    };
    const _realPrice = () => {
        if (dataInfo.appliedCoupon && dataInfo.couponObject) {
            let amount = _totalPrice();
            if (dataInfo.couponObject.amount_off) {
                amount = (amount - dataInfo.couponObject.amount_off).toFixed(2);
            } else if (dataInfo.couponObject.percent_off) {
                amount = ((1 - parseFloat(dataInfo.couponObject.percent_off) / 100) * amount).toFixed(2);
            }

            if (amount < 0) amount = 0;
            return amount;
        } else {
            return _totalPrice();
        }
    };

    return _totalPrice() - _realPrice();
};

const __getOldPlan = (selectPage, teamSubscription) => {
    let old_plan = null;
    if (selectPage === 'team') {
        old_plan = teamSubscription.package + '_' + teamSubscription.period;
    } else {
        old_plan = window.fj.currentPlan.package + '_' + window.fj.currentPlan.period;
    }
    return old_plan;
};

const __closeStripePopPanel = async (teamId, prepaymentId) => {
    const closeResult = await FJNetworkStore.closeStripePopPanel(teamId, prepaymentId);
    window.getStripePayInfo(closeResult.code, closeResult.data, closeResult.message);
};

export default {
    /**
     * @description stripe 订阅 支付
     * @returns {(function(*, *))|*}
     */
    stripePayAction: function (confirmationToken, options = {}) {
        return async (dispatch, getState) => {
            f_prepaymentId = null;
            const state = getState();
            const dataInfo = state.mainReducer.dataInfo;
            const planType = state.mainReducer.planType;
            const teamSubscription = state.mainReducer.teamSubscription;
            const currentPlan = state.mainReducer.currentPlan;
            const selectPage = state.mainReducer.selectPage;
            const isAnnually = state.mainReducer.isAnnually;
            const reducePlanData = state.mainReducer.planData;
            const currentCountryMonetaryISO = state.mainReducer.currentCountryMonetaryISO;

            const planDetail = __getPlanDetail(reducePlanData, selectPage);
            const isUpgrade = __isUpgrade(planType, teamSubscription, currentPlan);

            dispatch(mainAction.changeLoadingStatusAction(true));
            const { planData, paymentMethod } = options;
            const { plan, seats, teamId } = planData;

            const user_info = {
                browser: window?.fjuser?.getBrowser() || null,
                // geo: countryCode,
                device: window?.fjuser?.getDeviceInfo() || null,
            };

            // 付款有结果的回调
            window.getStripePayInfo = (code, data, message) => {
                clearInterval(fTimerInNewWindow);
                fTimerInNewWindow = null;

                dispatch(mainAction.changeLoadingStatusAction(false));

                switch (code) {
                    case 200: {
                        const discount = __getDiscount(isAnnually, planDetail, dataInfo);
                        const subscription = __successFn(dispatch, data, {
                            selectPage,
                            dataInfo,
                            discount,
                        });
                        if (isUpgrade) {
                            __successUpgradeFn(dispatch, subscription, { dataInfo, discount });
                            const old_plan = __getOldPlan(selectPage, teamSubscription);
                            __successUpgradeStatisticsEvent(subscription, {
                                old_plan,
                                dataInfo,
                                plan,
                                paymentMethod: confirmationToken?.payment_method_preview?.type
                                    ? confirmationToken?.payment_method_preview?.type === 'card'
                                        ? confirmationToken?.payment_method_preview?.card?.funding
                                        : confirmationToken?.payment_method_preview?.type
                                    : '100%code',
                            });
                        } else {
                            __successCreateFn(dispatch, subscription, { dataInfo, discount });
                            __successSubscriptStatisticsEvent(subscription, {
                                dataInfo,
                                plan,
                                paymentMethod: confirmationToken?.payment_method_preview?.type
                                    ? confirmationToken?.payment_method_preview?.type === 'card'
                                        ? confirmationToken?.payment_method_preview?.card?.funding
                                        : confirmationToken?.payment_method_preview?.type
                                    : '100%code',
                            });
                        }
                        break;
                    }
                    default: {
                        // stripe confirmPayment 报错
                        if (code === 501) {
                            FJMessageStore.addMessage(data.message, 'error');
                        }
                        if (isUpgrade) {
                            __failWithUpgradeCode(code);
                        } else {
                            __failWithCreateCode(code);
                        }
                    }
                }
            };

            let target = null;
            if (isUpgrade && window?.fj?.exchangeRate?.stripe_customer_has_card) {
            } else {
                target = window.open(
                    '',
                    '_blank',
                    'height=600,width=600,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
                );
                const openWidowFn = async url => {
                    const frame =
                        target ||
                        window.open(
                            url,
                            '_blank',
                            'height=600,width=600,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
                        );

                    if (target) {
                        target.location.href = url;
                    }

                    frame.opener = window;
                    return new Promise((resolve, reject) => {
                        fTimerInNewWindow = setInterval(async () => {
                            try {
                                if (frame.closed) {
                                    clearInterval(fTimerInNewWindow);
                                    fTimerInNewWindow = null;
                                    resolve();
                                    if (f_prepaymentId) {
                                        await __closeStripePopPanel(teamId, f_prepaymentId);
                                        dispatch(mainAction.changeLoadingStatusAction(false));
                                    }
                                }
                            } catch (e) {
                                clearInterval(fTimerInNewWindow);
                                fTimerInNewWindow = null;
                                reject(e);
                                dispatch(mainAction.changeLoadingStatusAction(false));
                            }
                        }, 100);
                    });
                };
                openWidowFn('/stripe-loading.html');
            }

            try {
                const result = await FJNetworkStore.createSubscription(
                    {
                        confirmation_token: confirmationToken?.id,
                        package: plan,
                        coupon: dataInfo.appliedCoupon,
                        user_info: user_info,
                        payment_method: confirmationToken?.payment_method_preview?.type,
                        currency: currentCountryMonetaryISO,
                    },
                    teamId,
                    seats,
                );
                const { code, data, message } = result;
                f_prepaymentId = data?.prepayment_id;
                if (target?.closed) {
                    if (code === 200 && !data.url) {
                        window.getStripePayInfo(code, data, message);
                    } else {
                        await __closeStripePopPanel(teamId, f_prepaymentId);
                    }
                } else {
                    if (code === 200 && data.url) {
                        if (target) {
                            target.location.href = data.url;
                        }
                    } else {
                        target && target.close();
                        window.getStripePayInfo(code, data, message);
                    }
                }
            } catch (e) {
                FJNetworkStore.closeStripePopPanel(teamId, f_prepaymentId);
            }
        };
    },

    /**
     * @description stripe 套餐支付（一次性）
     * @param confirmationToken
     * @param options
     */
    stripePayWithOneOffAction: function (confirmationToken, options = {}) {
        return async (dispatch, getState) => {
            f_prepaymentId = null;
            const state = getState();
            const dataInfo = state.mainReducer.dataInfo;
            const planType = state.mainReducer.planType;
            const teamSubscription = state.mainReducer.teamSubscription;
            const currentPlan = state.mainReducer.currentPlan;
            const selectPage = state.mainReducer.selectPage;
            const isAnnually = state.mainReducer.isAnnually;
            const reducePlanData = state.mainReducer.planData;

            const planDetail = __getPlanDetail(reducePlanData, selectPage);
            const isUpgrade = __isUpgrade(planType, teamSubscription, currentPlan);

            dispatch(mainAction.changeLoadingStatusAction(true));
            const { planData, paymentMethod } = options;
            const { plan, seats, teamId } = planData;

            const user_info = {
                browser: window?.fjuser?.getBrowser() || null,
                // geo: countryCode,
                device: window?.fjuser?.getDeviceInfo() || null,
            };

            // 付款有结果的回调
            window.getStripePayInfo = (code, data, message) => {
                clearInterval(fTimerInNewWindow);
                fTimerInNewWindow = null;

                dispatch(mainAction.changeLoadingStatusAction(false));

                switch (code) {
                    case 200: {
                        const discount = __getDiscount(isAnnually, planDetail, dataInfo);
                        const subscription = __successFn(dispatch, data, {
                            selectPage,
                            dataInfo,
                            discount,
                        });
                        if (isUpgrade) {
                            __successUpgradeFn(dispatch, subscription, { dataInfo, discount });
                            const old_plan = __getOldPlan(selectPage, teamSubscription);
                            __successUpgradeStatisticsEvent(subscription, {
                                old_plan,
                                dataInfo,
                                plan,
                                paymentMethod: confirmationToken?.payment_method_preview?.type
                                    ? confirmationToken?.payment_method_preview?.type === 'card'
                                        ? confirmationToken?.payment_method_preview?.card?.funding
                                        : confirmationToken?.payment_method_preview?.type
                                    : '100%code',
                            });
                        } else {
                            __successCreateFn(dispatch, subscription, { dataInfo, discount });
                            __successSubscriptStatisticsEvent(subscription, {
                                dataInfo,
                                plan,
                                paymentMethod: confirmationToken?.payment_method_preview?.type
                                    ? confirmationToken?.payment_method_preview?.type === 'card'
                                        ? confirmationToken?.payment_method_preview?.card?.funding
                                        : confirmationToken?.payment_method_preview?.type
                                    : '100%code',
                            });
                        }
                        break;
                    }
                    default: {
                        if (isUpgrade) {
                            __failWithUpgradeCode(code);
                        } else {
                            __failWithCreateCode(code);
                        }
                    }
                }
            };

            const target = window.open(
                '',
                '_blank',
                'height=600,width=600,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
            );
            const openWidowFn = async url => {
                const frame =
                    target ||
                    window.open(
                        url,
                        '_blank',
                        'height=600,width=600,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
                    );

                if (target) {
                    target.location.href = url;
                }

                frame.opener = window;
                return new Promise((resolve, reject) => {
                    fTimerInNewWindow = setInterval(async () => {
                        try {
                            if (frame.closed) {
                                clearInterval(fTimerInNewWindow);
                                fTimerInNewWindow = null;
                                resolve();
                                if (f_prepaymentId) {
                                    await __closeStripePopPanel(teamId, f_prepaymentId);
                                    dispatch(mainAction.changeLoadingStatusAction(false));
                                }
                            }
                        } catch (e) {
                            clearInterval(fTimerInNewWindow);
                            fTimerInNewWindow = null;
                            reject(e);
                            dispatch(mainAction.changeLoadingStatusAction(false));
                        }
                    }, 100);
                });
            };

            openWidowFn('/stripe-loading.html');

            try {
                const result = await FJNetworkStore.createOneTimePayment(
                    {
                        confirmation_token: confirmationToken?.id,
                        package: plan,
                        coupon: dataInfo.appliedCoupon,
                        user_info: user_info,
                        payment_method: confirmationToken?.payment_method_preview?.type,
                    },
                    teamId,
                    seats,
                );
                const { code, data, message } = result;
                f_prepaymentId = data?.prepayment_id;
                if (target?.closed) {
                    if (code === 200 && !data.url) {
                        window.getStripePayInfo(code, data, message);
                    } else {
                        await __closeStripePopPanel(teamId, f_prepaymentId);
                    }
                } else {
                    if (code === 200 && data.url) {
                        if (target) {
                            target.location.href = data.url;
                        }
                    } else {
                        target && target.close();
                        window.getStripePayInfo(code, data, message);
                    }
                }
            } catch (e) {
                FJNetworkStore.closeStripePopPanel(teamId, f_prepaymentId);
            }
        };
    },

    /**
     * @description stripe 积分支付
     * @param confirmationToken
     * @param options
     */
    stripePayInCreditsAction: function (confirmationToken, options = {}) {
        return async (dispatch, getState) => {
            f_prepaymentId = null;
            const state = getState();
            const { info: currentCheckedCreditPackage } = state.mainReducer.creditsPayData;
            const dataInfo = state.mainReducer.dataInfo;
            const currentCountryMonetaryISO = state.mainReducer.currentCountryMonetaryISO;
            dispatch(mainAction.changeLoadingStatusAction(true));

            const user_info = {
                browser: window?.fjuser?.getBrowser() || null,
                // geo: countryCode,
                device: window?.fjuser?.getDeviceInfo() || null,
            };
            const entry = state.mainReducer.entry;

            // 付款有结果的回调
            window.getStripePayInfo = (code, data, message) => {
                clearInterval(fTimerInNewWindow);
                fTimerInNewWindow = null;

                dispatch(mainAction.changeLoadingStatusAction(false));

                switch (code) {
                    case 200: {
                        // 打开支付成功的问卷页面
                        if (FJUtil.isMobile()) {
                            dispatch(FJMobilePayAction.setMobilePayStep(FJViewConstant.PAY_SUCCESS));
                            dispatch(FJMobilePayAction.updateCreditsNumAction());
                        } else {
                            dispatch(mainAction.changeBuyCreditsSuccessPage(true));
                        }

                        const payInfo = data && data.credits ? data.credits : {};

                        dispatch(
                            mainAction.setDataInfo({
                                amount: payInfo.price,
                                period_end: payInfo.period_end,
                                sub_type: payInfo.sub_type,
                                transaction_id: payInfo.transaction_id,
                            }),
                        );

                        // 订阅成功关闭折扣码
                        let obj = {};
                        obj.openDiscount = false;
                        dispatch(mainAction.setDiscountConfAction(obj));

                        window.FJGlobalariable.modSubscription.state.page = 'successful';
                        // 调用这个方法，更新login-button中的积分显示
                        if (window.fj && window.fj.event && window.fj.event.dispatch) {
                            window.fj.event.dispatch('UPDATE_USER_CREDITS');
                        }

                        FJUtil.ealog('ai credits revenue', {
                            'credit type':
                                currentCheckedCreditPackage.base +
                                (currentCheckedCreditPackage.present ? `+${currentCheckedCreditPackage.present}` : ''),
                            'coupon code off':
                                dataInfo.appliedCoupon &&
                                (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
                            platform: 'stripe',
                            'coupon code': dataInfo.appliedCoupon,
                            // location: cityCodeRef.current,
                            price: payInfo.price,
                            'stripe method': confirmationToken?.payment_method_preview?.type
                                ? confirmationToken?.payment_method_preview?.type === 'card'
                                    ? confirmationToken?.payment_method_preview?.card?.funding
                                    : confirmationToken?.payment_method_preview?.type
                                : '100%code',
                            'ai tools': entry,
                            '10.9 eur-pay ab test': FJUtil.getPayCur(),
                        });

                        window.gaEvent('ai credits revenue', window.FJGlobalariable.modSubscription.entry, '');
                        break;
                    }
                    case 214:
                        FJUtil.checkLogin(code);
                        window.FJGlobalariable.modSubscription.noLoginResponseCallback();
                        break;
                    case 335:
                        FJUtil.checkLogin(code);
                        break;
                    case 501:
                        // stripe confirmPayment 报错
                        FJMessageStore.addMessage(data.message, 'error');
                        break;
                    default: {
                        if (window.fj.currentPlan.package === 'free') {
                            FJMessageStore.addMessage(FJLocalStore._('PLEASE_USE_PAYPAL'), 'error');
                        }
                    }
                }
            };

            const target = window.open(
                '',
                '_blank',
                'height=600,width=600,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
            );
            const openWidowFn = async url => {
                const frame =
                    target ||
                    window.open(
                        url,
                        '_blank',
                        'height=600,width=600,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
                    );

                if (target) {
                    target.location.href = url;
                }

                frame.opener = window;
                return new Promise((resolve, reject) => {
                    fTimerInNewWindow = setInterval(async () => {
                        try {
                            if (frame.closed) {
                                clearInterval(fTimerInNewWindow);
                                fTimerInNewWindow = null;
                                if (f_prepaymentId) {
                                    await __closeStripePopPanel(null, f_prepaymentId);
                                    dispatch(mainAction.changeLoadingStatusAction(false));
                                }
                                resolve();
                            }
                        } catch (e) {
                            clearInterval(fTimerInNewWindow);
                            fTimerInNewWindow = null;
                            reject(e);
                            dispatch(mainAction.changeLoadingStatusAction(false));
                        }
                    }, 100);
                });
            };

            openWidowFn('/stripe-loading.html');

            try {
                const result = await FJNetworkStore.buyCredits({
                    confirmation_token: confirmationToken?.id,
                    package: currentCheckedCreditPackage.packageName,
                    coupon: dataInfo.appliedCoupon,
                    user_info: user_info,
                    payment_method: confirmationToken?.payment_method_preview?.type,
                    currency: currentCountryMonetaryISO,
                });
                const { code, data, message } = result;
                f_prepaymentId = data?.prepayment_id;
                if (target?.closed) {
                    if (code === 200 && !data.url) {
                        window.getStripePayInfo(code, data, message);
                    } else {
                        await __closeStripePopPanel(null, f_prepaymentId);
                    }
                } else {
                    if (code === 200 && data.url) {
                        if (target) {
                            target.location.href = data.url;
                        }
                    } else {
                        target && target.close();
                        window.getStripePayInfo(code, data, message);
                    }
                }
            } catch (e) {
                FJNetworkStore.closeStripePopPanel(null, f_prepaymentId);
            }
        };
    },

    changeLoadStripeUIStatusAction: function (status) {
        return {
            type: FJActionConstant.CHANGE_LOAD_STRIPE_UI_STATUS,
            data: status,
        };
    },
};
