import FJEventConstant from '../constant/FJEventConstant';
import useForceUpdate from './useForceUpdate';
import { useEffect, useRef } from 'react';
import FJEvent from '../lib/FJEvent';

function useLocalStore() {
    const forupdateRef = useRef(useForceUpdate());
    const forceUpdate = forupdateRef.current;
    useEffect(() => {
        return FJEvent.subscribe(FJEventConstant.LANGUAGE_CHANGED, forceUpdate);
    }, [forceUpdate]);
}

export default useLocalStore;
