import Style from './Credits.module.less';
import { useState, useRef, useMemo, useLayoutEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import FJLocalStore from '../../../store/FJLocalStore';
import InlineSvg from 'react-inlinesvg';

import CreditsSvg from '../../../icon/Credits.svg';
import checkSvg from '../../../icon/check.svg';
import addSvg from '../../../icon/icon-add2.svg';
import freeGiftSvg from '../../../icon/free-gift.svg';

import FJUtil from '../../../util/FJUtil';
import useIsMobile from '../../../hooks/useIsMobile';
import FJCreditsPackageViewContainer from '../../FJCreditsMobile/FJCreditsPackageView/FJCreditsPackageView.container';

export default function Credits(props) {
    // 是否是移动端，是的话，仅对积分套餐做展示，不做交互
    let isMobile = useIsMobile();

    const { purchase = [], getUserInfo = () => {}, changeCheckedCredits } = props;
    const creditsPayData = useSelector(state => state.mainReducer.creditsPayData);
    const showCreditRuleTable = useSelector(state => state.mainReducer.showCreditRuleTable);
    const method = useSelector(state => state.mainReducer.method);
    const isPopup = method === 'popup';

    // 当前用户所在地区货币符号
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);

    const [domWidth, setDomWidth] = useState(0);

    const checkedPurchase = creditsPayData.index;
    let __drawPurchaseView = () => {
        if (isMobile) {
            return <FJCreditsPackageViewContainer />;
        }
        let purchaseItemClick = index => {
            if (checkedPurchase === index) {
                return;
            }
            changeCheckedCredits(index);
        };

        return (
            <ul className={Style.purchase} style={{ '--boxWidth': domWidth * 2 + 13 + 'px' }}>
                {Array.isArray(purchase) &&
                    purchase.map((item, index) => {
                        return (
                            <li
                                key={index}
                                className={classnames(Style.purchaseItem, {
                                    [Style.purchaseItemActive]: checkedPurchase === index,
                                })}
                                onClick={() => {
                                    purchaseItemClick(index);
                                }}
                                style={{ '--width': domWidth + 'px' }}
                            >
                                <div className={Style.purchaseItemIconBox}>
                                    <InlineSvg src={CreditsSvg} />
                                </div>
                                <div
                                    className={classnames(Style.baseCreditsBox, {
                                        [Style.baseCreditsBoxWithNoPresent]: !item.present,
                                    })}
                                >
                                    {item.base}&nbsp;{FJLocalStore._(item.baseUnit)}
                                    {item.base < 1000 && <>&nbsp;&nbsp;&nbsp;</>}
                                </div>
                                {item.present ? (
                                    <>
                                        <div className={classnames(Style.purchaseItemIconBox, Style.addIcon)}>
                                            <InlineSvg src={addSvg} />
                                        </div>
                                        <div className={Style.purchaseItemIconBox}>
                                            <InlineSvg src={freeGiftSvg} />
                                        </div>
                                        <span className={Style.presentCreditsBox}>
                                            {item.present}&nbsp;
                                            {FJLocalStore._(item.presentUnit)}
                                        </span>
                                    </>
                                ) : (
                                    ''
                                )}
                                <div className={Style.purchaseItemPrice}>
                                    <span className={Style.currentCountryMonetary}>
                                        {currentCountryMonetary || item.priceUnit}
                                        {currentCountryMonetaryISO}
                                    </span>
                                    <span className={Style.currentCountryMonetaryPrice}>
                                        {FJUtil.formatNumberThousandth(
                                            FJUtil.preciseMultiply([parseFloat(item.price), exchangeRate]).toFixed(2),
                                        )}
                                    </span>
                                </div>
                                <div className={Style.purchaseItemChecked}>
                                    <InlineSvg src={checkSvg} />
                                </div>
                            </li>
                        );
                    })}
            </ul>
        );
    };

    // 选择积分套餐后点击跳转去支付
    const chooseCreditsAndToPayPanel = () => {
        if (typeof checkedPurchase !== 'number') {
            return;
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        getUserInfo && getUserInfo();
    };

    let __drawRulesTable = () => {
        let __drawTableBodyRows = new Array(16).fill(null).map((item, index) => {
            return (
                <tr key={index} className={Style.tableBorder}>
                    <td align='left'>{FJLocalStore._(`rules-line-${index + 1}-1`)}</td>
                    <td>{FJLocalStore._(`rules-line-${index + 1}-2`)}</td>
                </tr>
            );
        });

        return (
            <table className={Style.rulesTable}>
                <thead>
                    <tr>
                        <th style={{ borderRight: '1px solid #E6E9ED' }} align='left'>
                            {FJLocalStore._('rules-line-0-1')}
                        </th>
                        <th>{FJLocalStore._('rules-line-0-2')}</th>
                    </tr>
                </thead>
                <tbody>{__drawTableBodyRows}</tbody>
            </table>
        );
    };

    const jumpToPricingCredits = () => {
        const url = FJUtil.getCurrentUrl() + '/pricing?panelName=Credits';
        window.open(url, '_blank');
    };

    // 获取全部dom中最宽的元素
    let _calculateDomMaxWidth = () => {
        const liList = document.getElementsByClassName(Style.purchaseItem);
        let maxWidth = 0;
        for (let i = 0; i < liList.length; i++) {
            const rec = liList[i].getBoundingClientRect();
            maxWidth = Math.max(rec.width, maxWidth);
        }

        setDomWidth(maxWidth);
    };
    useLayoutEffect(() => {
        _calculateDomMaxWidth();
    });
    const boxStyle = isPopup
        ? {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
          }
        : {};
    return (
        <div className={Style.Credits} style={boxStyle}>
            <div>
                <h2 className={Style.title}>{FJLocalStore._('credits-title')}</h2>
                <p className={Style.desc} dangerouslySetInnerHTML={{ __html: FJLocalStore._('credits-desc') }} />
                {__drawPurchaseView()}
            </div>
            <button className={Style.startNowBtn} onClick={chooseCreditsAndToPayPanel}>
                {FJLocalStore._('get-now')}
            </button>
            <p className={Style.creditsValidity}>
                {FJLocalStore._('CreditsValidity')}&nbsp;
                {isPopup && <span onClick={jumpToPricingCredits}>{FJLocalStore._('lern-more')}</span>}
            </p>
            {showCreditRuleTable && !isPopup && (
                <>
                    <h3 className={Style.exchangeRules}>{FJLocalStore._('ExchangeRules')}</h3>
                    {__drawRulesTable()}
                </>
            )}
        </div>
    );
}
