export default {
    BACKGROUND_REMOVAL: 'BACKGROUND_REMOVAL',
    AUTO_SUBTITLE: 'AUTO_SUBTITLE',
    AI_QUOTA: 'AI_QUOTA',
    SUBTITLE_LIMIT: 'SUBTITLE_LIMIT',
    AI_IMAGE_LIMIT: 'AI_IMAGE_LIMIT',
    AI_SCRIPT_LIMIT: 'AI_SCRIPT_LIMIT',
    AI_VIDEO_LIMIT: 'AI_VIDEO_LIMIT',
    TEXT_TO_SPEECH_LIMIT: 'TEXT_TO_SPEECH_LIMIT',
    REMOVE_BACKGROUND_LIMIT: 'REMOVE_BACKGROUND_LIMIT',
    TRANSLATE_LIMIT: 'TRANSLATE_LIMIT',
    AUDIO_REDUCE_LIMIT: 'AUDIO_REDUCE_LIMIT',
    VOCAL_REMOVER_LIMIT: 'VOCAL_REMOVER_LIMIT',

    TITLE: 'TITLE',
    FREE: 'free',
    PLUS: 'plus',
    BUSINESS: 'business',

    CREDITS: 'CREDITS',
    ONE_TIME_PAYMENT: 'ONE_TIME_PAYMENT',

    START: 'START',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS',
    NONE: 'NONE',
    CREDITS_CENTER: 'CREDITS_CENTER',
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    PAY_SUCCESS: 'PAY_SUCCESS',
};
