import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FJCheckoutForm from './FJCheckoutForm';
import React, { useEffect, useState } from 'react';
import FJLocalStore from '../../../store/FJLocalStore';
import FJNetworkStore from '../../../store/FJNetworkStore';
import FJConfig from '../../../config/FJConfig';

const stripePromise = loadStripe(FJConfig.stripeKey);

const FJStripePay = (props, ref) => {
    const {
        stripePay,
        changeLoadingStatus,
        mode = 'subscription',
        loadStripeUIStatus,
        currentCountryMonetaryISO,
        style = { marginBottom: '30px' },
        boxClassName,
    } = props;

    const [customerSessionClientSecret, setCustomerSessionClientSecret] = useState('');

    useEffect(() => {
        const getCustomerSession = async () => {
            try {
                const response = await FJNetworkStore.getCustomerSession();
                if (response.code === 200) {
                    setCustomerSessionClientSecret(response.data.customer_session_client_secret);
                }
            } catch (e) {
                console.error('getCustomerSession error', e);
            }
        };
        getCustomerSession();
    }, []);

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#080a10',
            colorBackground: '#ffffff',
            colorText: '#080a10',
            colorDanger: '#ff303d',
            borderRadius: '10px',
            fontSizeBase: '16px',
            fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
            // See all possible variables below
        },
        rules: {
            '.Input--invalid': {
                boxShadow: 'none',
            },
            '.Input:focus': {
                boxShadow: 'none',
                border: '1px solid #ff822c',
            },
            '.Input::placeholder': {
                boxShadow: 'none',
                color: 'rgba(26,27,28,0.45)',
                fontSize: '15px',
            },
            '.Label': {
                fontSize: '15px',
                fontWeight: '500',
                color: '#080a10',
                marginBottom: '8px',
                marginTop: '4px',
            },
            '.Tab': {},
            '.Tab:focus': {
                boxShadow: 'none',
                border: '1px solid #ff822c',
                outline: 'none',
            },
            '.Tab--selected': {
                boxShadow: 'none',
                border: '1px solid #ff822c',
                outline: 'none',
            },
            '.Tab--selected:hover': {
                boxShadow: 'none',
                border: '1px solid #ff822c',
                outline: 'none',
            },
            '.Tab--selected:focus': {
                boxShadow: 'none',
                border: '1px solid #ff822c',
                outline: 'none',
            },
            '.Tab:active': {
                boxShadow: 'none',
                border: '1px solid #ff822c',
                outline: 'none',
            },

            '.TabLabel': {
                fontSize: '13px',
                fontWeight: 'Medium',
                color: '#080a10',
            },

            '.TabLabel--selected': {
                color: '#ff822c',
            },

            '.CheckboxLabel': {
                fontSize: '14px',
            },
        },
    };

    const currentTag = FJLocalStore.currentTag();
    const languageJson = {
        cn: 'zh',
        de: 'de',
        en: 'en',
        es: 'es',
        fr: 'fr',
        jp: 'ja',
        pt: 'pt',
        tw: 'zh-TW',
        ar: 'ar',
    };
    // ar 阿拉伯语
    const options = {
        appearance,
        mode: mode, // subscription payment
        amount: 1099,
        currency: currentCountryMonetaryISO === 'EUR' ? 'eur' : 'usd',
        locale: languageJson[currentTag],
        // paymentMethodCreation: 'manual',

        // 是否渲染组件
        customerSessionClientSecret: customerSessionClientSecret,
    };

    return (
        <div style={style} className={boxClassName}>
            {customerSessionClientSecret && (
                <Elements stripe={stripePromise} options={options}>
                    <FJCheckoutForm
                        ref={ref}
                        stripePay={stripePay}
                        changeLoadingStatus={changeLoadingStatus}
                        loadStripeUIStatus={loadStripeUIStatus}
                    />
                </Elements>
            )}
        </div>
    );
};

export default React.forwardRef(FJStripePay);
