import closeIcon from '../../../icon/close.svg';
import InlineSVG from 'react-inlinesvg';
import Style from './CloseButton.module.less';
import classNames from 'classnames';

const CloseButton = props => {
    let { onClick, className } = props;
    return (
        <div onClick={onClick} className={classNames(Style.box, className)}>
            <InlineSVG src={closeIcon} />
        </div>
    );
};
export default CloseButton;
