import Style from './PaypalBtn.module.less';
import { useEffect, useRef, useState } from 'react';
import FJLocalStore from '../../store/FJLocalStore';
import FJMessageStore from '../../store/FJMessageStore';
import { useSelector } from 'react-redux';

import paypalBtnBase64 from '../../assets/base64/paypay-btn-base64';
let PaypalBtn = props => {
    let { style } = props;

    const dataInfo = useSelector(state => state.mainReducer.dataInfo);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const { info: currentCheckedCreditPackage } = useSelector(state => state.mainReducer.creditsPayData);

    let buttons = useRef(null);
    let hasRendered = useRef(false);
    let [hasPop, setHasPop] = useState(false);

    useEffect(() => {
        return () => {
            if (buttons.current && buttons.current.close && hasRendered.current) {
                buttons.current.close();
                hasRendered.current = false;
                buttons.current = null;
            }
        };
    }, []);

    const paypalRunning = () => {
        if (hasPop) {
            return;
        }
        setHasPop(true);
        // 当前没有选择的积分包则不进行后续操作
        if (!currentCheckedCreditPackage || !currentCheckedCreditPackage.packageName) {
            return;
        }

        const paypalWindow = window.open(
            '',
            '_blank',
            'height=600,width=600,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
        );
        //  getCrediteSubscribeId 查询支付状态
        window.FJGlobalariable.modSubscription.network
            .getPayPalCreditsWindowUrl(
                currentCheckedCreditPackage.packageName,
                dataInfo.appliedCoupon,
                currentCountryMonetaryISO,
            )
            .then(response => {
                if (response.code === 200 && response.data?.approvalUrl) {
                    return new Promise((resolve, reject) => {
                        paypalWindow.location.href = response.data.approvalUrl;
                        paypalWindow.opener = window;
                        const loop = setInterval(() => {
                            try {
                                if (paypalWindow.closed) {
                                    clearInterval(loop);
                                    resolve();
                                }
                            } catch (e) {
                                clearInterval(loop);
                                reject(e);
                            }
                        }, 100);
                    });
                } else {
                    paypalWindow && paypalWindow.close && paypalWindow.close();
                    throw new Error(response.code);
                }
            })
            .catch(error => {
                if (error?.message === '429') {
                    FJMessageStore.addMessage(FJLocalStore._('FAIL_WITH_TOO_MANY_REQUESTS'), 'error');
                } else {
                    FJMessageStore.addMessage(FJLocalStore._('CONNECT_NETWORK_FAILED'), 'error');
                }
            })
            .finally(() => setHasPop(false));
    };
    return (
        <>
            <div className={Style.paypal_box_new} style={style} onClick={paypalRunning}>
                <img width={63} height={20} src={paypalBtnBase64} alt='' />
            </div>
            {hasPop && (
                <div className={Style.mask_box}>
                    <div className={Style.mask} />
                </div>
            )}
        </>
    );
};
export default PaypalBtn;
