import { useSelector } from 'react-redux';
import FJUtil from '../util/FJUtil';
import { useEffect, useState } from 'react';

const getTeamPayType = () => window.fj?.exchangeRate?.team_pay_type || '';
const getPersonalPayType = () => window.fj?.exchangeRate?.personal_pay_type || '';

export function useINOneTimePayment() {
    const isIN = useSelector(state => state.mainReducer.currentCountry === 'IN');

    // '', free, subscription, one_time_payment
    const [teamPayType, setTeamPayType] = useState(getTeamPayType);
    const [personalPayType, setPersonalPayType] = useState(getPersonalPayType);
    const [login, setLogin] = useState(FJUtil.isLogin());

    const isTeam = useSelector(state => state.mainReducer.planType === 'team');

    useEffect(() => {
        if (!window.fjuser?.addListener) {
            return;
        }
        const fn = result => {
            result && setLogin(true);
            setTeamPayType(getTeamPayType());
            setPersonalPayType(getPersonalPayType());
        };
        window.fjuser.addListener(window.fjuser.eventType.login, fn);
        return () => window.fjuser.removeListener(window.fjuser.eventType.login, fn);
    }, []);
    useEffect(() => {
        if (!window.fjuser?.addListener) {
            return;
        }
        const fn = result => {
            result && setLogin(false);
            setTeamPayType(getTeamPayType());
            setPersonalPayType(getPersonalPayType());
        };
        window.fjuser.addListener(window.fjuser.eventType.logout, fn);
        return () => window.fjuser.removeListener(window.fjuser.eventType.logout, fn);
    }, []);

    if (!login) {
        return isIN;
    }

    if (isTeam && teamPayType && teamPayType !== 'free') {
        return teamPayType === 'one_time_payment';
    }

    if (!isTeam && personalPayType && personalPayType !== 'free') {
        return personalPayType === 'one_time_payment';
    }

    return isIN;
}
