/* eslint-disable no-cond-assign */
import $ from 'jquery';
import FJConfig from '../config/FJConfig';
import Download from '../lib/download';
import FJLocalStore from '../store/FJLocalStore';
import FJMessageStore from '../store/FJMessageStore';
import FJNetworkStore from '../store/FJNetworkStore';

let loginCallback = [];
let fUserID = '';
let fProjectID = '';
let _isNewPay = false;

const Util = {
    // getJumpUrl: function (url) {
    //     let tag = window.language_tag === 'en' ? '/' : '/' + window.language_tag
    //     let URL = tag + url
    //     return URL
    // },
    isNewPay: function () {
        return _isNewPay;
    },
    setIsNewPay: function (bool) {
        _isNewPay = bool;
    },
    getCurrentUrl: function () {
        let lange = FJLocalStore.currentTag();
        let url;
        if (lange === 'en' || lange === 'it') {
            url = FJConfig.serverPath;
        } else {
            url = FJConfig.serverPath + '/' + lange;
        }
        return url;
    },

    moveEnd: function (obj) {
        obj.focus();
        let len = obj.value.length;
        if (document.selection) {
            let sel = obj.createTextRange();
            sel.moveStart('character', len);
            sel.collapse();
            sel.select();
        } else if (typeof obj.selectionStart === 'number' && typeof obj.selectionEnd === 'number') {
            obj.selectionStart = obj.selectionEnd = len;
        }
    },
    filterStr: function (str) {
        return str.replace(/<script>|<\/script>/g, '');
    },
    getSelectionLength: function (myField) {
        return myField.selectionEnd - myField.selectionStart;
    },
    insertAtCursor: function (myField, myValue) {
        // IE support
        if (document.selection) {
            myField.focus();
            let sel = document.selection.createRange();
            sel.text = myValue;
        }
        // MOZILLA and others
        else if (myField.selectionStart || myField.selectionStart === 0) {
            let startPos = myField.selectionStart;
            let endPos = myField.selectionEnd;
            myField.value =
                myField.value.substring(0, startPos) + myValue + myField.value.substring(endPos, myField.value.length);
        } else {
            myField.value += myValue;
        }

        return myField.value;
    },
    setShareUserID: function (id) {
        fUserID = id;
    },
    getShareUserID: function () {
        return fUserID;
    },
    setShareProjectID: function (id) {
        fProjectID = id;
    },
    getShareProjectID: function () {
        return fProjectID;
    },
    getUserId: function () {
        return window.fjuser && window.fjuser.info && window.fjuser.info.id
            ? window.fjuser && window.fjuser.info && window.fjuser.info.id
            : '';
    },
    getQueryString: function (name) {
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        let r = window.location.search.substr(1).match(reg);
        if (!r) return unescape(r[2]);
        else return null;
    },
    getLanguage: function (tag, callback) {
        fetch(FJConfig.languagePath + tag + '.json?v=' + FJConfig.buildVersion)
            .then(res => res.json())
            .then(json => {
                callback(json);
            })
            .catch(err => console.log(err));
    },
    addEvent: function (element, event, handler, useCapture = false) {
        if (!element) {
            return;
        }
        if (element.addEventListener) {
            element.addEventListener(event, handler, useCapture);
        } else if (element.attachEvent) {
            element.attachEvent('on' + event, handler);
        }
    },
    removeEvent: function (element, event, handler, useCapture = false) {
        if (!element) {
            return;
        }
        if (element.removeEventListener) {
            element.removeEventListener(event, handler, useCapture);
        } else if (element.detachEvent) {
            element.detachEvent('on' + event, handler);
        }
    },
    formatTime: function (time) {
        let m = Math.floor(time / 60);
        let s = Math.floor(time % 60);
        if (s < 10) s = '0' + s;
        return m + ':' + s;
    },
    valueOfInterval(value, min, max) {
        let result = 0;
        result = value < min ? min : value;
        result = result > max ? max : result;
        return result;
    },
    isPanelArea: function (e, object) {
        if (!e) {
            return false;
        }
        let point = {
            x: e.clientX,
            y: e.clientY,
        };

        try {
            let rect = {
                x: object.getBoundingClientRect().left,
                y: object.getBoundingClientRect().top,
                width: $(object).outerWidth(),
                height: $(object).outerHeight(),
            };
            return this.isPointInRect(point, rect);
        } catch (e) {
            return false;
        }
    },
    isPointInRect: function (point, rect) {
        return (
            point.x >= rect.x && point.x <= rect.x + rect.width && point.y >= rect.y && point.y <= rect.y + rect.height
        );
    },
    isLocalhost: function () {
        return Boolean(
            window.location.hostname === 'localhost' ||
                // [::1] is the IPv6 localhost address.
                window.location.hostname === '[::1]' ||
                // 127.0.0.1/8 is considered localhost for IPv4.
                window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
                window.location.hostname.match(/^192.168(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}$/),
        );
    },
    isLogin: function () {
        return window.fjuser && window.fjuser.info && window.fjuser.info.id;
    },
    anonymousSignUp: function () {
        return new Promise((resolve, reject) => {
            if (window.PanelFunc && window.PanelFunc.showSignUpPane) {
                window.PanelFunc.showSignUpPane();
                window.loginToCreateProjectCallback = () => {
                    loginCallback.forEach(callback => {
                        callback();
                    });
                    resolve();
                };
            } else {
                reject(new Error('error in anonymousSignUp'));
            }
        });
    },
    downloadVideo: function (videoUrl) {
        if (this.getBrowser() === 'FF') {
            Download(videoUrl);
        } else {
            const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
            save_link.href = videoUrl;
            save_link.click();
        }
    },
    copy: function (text) {
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.value = text; // 修改文本框的内容
        input.select(); // 选中文本
        document.execCommand('copy'); // 执行浏览器复制命令
        document.body.removeChild(input);
    },
    toLocalTime: function (time) {
        return time - new Date(time).getTimezoneOffset() * 60000;
    },
    getNextMonthSameDay: function (currentDate) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const day = currentDate.getDate();

        // 计算下一个月的年份和月份
        const nextMonth = (month + 1) % 12;
        const nextYear = month === 11 ? year + 1 : year;

        // 创建一个新的日期对象，设置为下一个月的同一天
        const nextMonthDate = new Date(nextYear, nextMonth, day);

        // 处理月份溢出问题
        if (nextMonthDate.getMonth() !== nextMonth) {
            nextMonthDate.setDate(0); // 设置为上个月的最后一天
        }

        return nextMonthDate;
    },
    getNextYearSameDay: function (currentDate) {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth(); // 0 是一月，1 是二月
        const day = currentDate.getDate();

        // 创建一个新的日期对象，增加一年
        const nextYearDate = new Date(currentDate);
        nextYearDate.setFullYear(year + 1);

        // 如果原始日期是2月29日，并且下一年没有2月29日，设为2月28日
        if (month === 1 && day === 29) {
            // 二月是1（0-based索引）
            const nextYear = nextYearDate.getFullYear();
            // 判断下一年是否为闰年，如果不是则设为2月28日
            if (nextYear % 4 !== 0 || (nextYear % 100 === 0 && nextYear % 400 !== 0)) {
                nextYearDate.setDate(28);
            }
        }

        return nextYearDate;
    },
    formatTimeZone: function (time) {
        let date = new Date(time);
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let Y = date.getFullYear();
        let M = month[date.getMonth()] + ' ';
        let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ', ';
        return M + D + Y;
    },
    /**
     * @desc 转换时间格式
     * @param {string} date - 例如2020-07-03 06:35:55
     * @return {number}
     */
    convertTime: function (date) {
        let newDate = date.substring(0, 19);
        newDate = newDate.replace(/-/g, '/');
        return new Date(newDate).getTime();
    },
    /**
     * @description 格式化数字（货币格式）
     * @param num
     * @returns {string}
     */
    toThousands: function (num) {
        return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    },

    /**
     * @description 判断是不是测试环境
     * @returns {boolean}
     */
    isTest: function () {
        return import.meta.env.VITE_CURRENT_PUBLIC_SERVER_TAG_TYPE !== 'release';
    },

    /**
     * @description 判断是否是手机端访问
     * @returns {boolean}
     */
    isMobile: function () {
        const ua = navigator.userAgent.toLowerCase();
        return !!ua.match(/mobile/);
    },

    /**
     * @desc 获取当前浏览器
     * @return {string}
     */
    getBrowser: function () {
        const userAgentDataBrowserList = ['Google Chrome', 'Opera', 'Microsoft Edge'];
        if (navigator && navigator.userAgentData) {
            const userAgentData = navigator.userAgentData;
            const isMobile = userAgentData.mobile ? '.Mobile' : '';
            const brands = userAgentData.brands;

            let browser = '';
            for (let i = 0; i < brands.length; i++) {
                const brand = brands[i].brand;
                if (userAgentDataBrowserList.indexOf(brand) !== -1) {
                    switch (brand) {
                        case userAgentDataBrowserList[0]: {
                            browser = 'Chrome' + isMobile;
                            break;
                        }
                        case userAgentDataBrowserList[1]: {
                            browser = 'Opera' + isMobile;
                            break;
                        }
                        case userAgentDataBrowserList[2]: {
                            browser = 'Edge' + isMobile;
                            break;
                        }
                    }
                    break;
                }
            }

            if (browser) {
                return browser;
            }
        }

        let Sys = {};
        let ua = navigator.userAgent.toLowerCase();
        let s;
        let isMobile = '';

        // eslint-disable-next-line no-unused-expressions
        (s = ua.match(/rv:([\d.]+)\) like gecko/))
            ? (Sys.ie = s[1])
            : (s = ua.match(/msie ([\d.]+)/))
            ? (Sys.ie = s[1])
            : (s = ua.match(/edge\/([\d.]+)/))
            ? (Sys.edge = s[1])
            : (s = ua.match(/edg\/([\d.]+)/))
            ? (Sys.edge = s[1])
            : (s = ua.match(/firefox\/([\d.]+)/))
            ? (Sys.firefox = s[1])
            : (s = ua.match(/opera.([\d.]+)/))
            ? (Sys.opera = s[1])
            : (s = ua.match(/opr\/([\d.]+)/))
            ? (Sys.opera = s[1])
            : (s = ua.match(/chrome\/([\d.]+)/))
            ? (Sys.chrome = s[1])
            : (s = ua.match(/version\/([\d.]+).*safari/))
            ? (Sys.safari = s[1])
            : 0;

        if (ua.match(/mobile/)) {
            isMobile = '.Mobile';
        }

        if (Sys.ie) return 'IE' + isMobile;
        if (Sys.edge) return 'Edge' + isMobile;
        if (Sys.firefox) return 'FF' + isMobile;
        if (Sys.opera) return 'Opera' + isMobile;
        if (Sys.safari) return 'Safari' + isMobile;
        if (Sys.chrome) return 'Chrome' + isMobile;
    },

    /**
     * @description 埋点统计
     * @param eventType
     * @param eventData
     */
    ealog: function (eventType, eventData) {
        if (window && window.ealog && window.ealog.addEvent) {
            window.ealog.addEvent(eventType, eventData);
        } else {
            console.log(eventType, eventData);
        }
    },
    // 诸葛统计入口
    zgEventLog: function (eventType, eventData) {
        if (window && window.zgEventLog && window.zgEventLog.addEvent) {
            window.zgEventLog.addEvent(eventType, eventData);
        } else {
            console.log(eventType, eventData);
        }
    },
    getPricingUri: function () {
        return FJLocalStore.currentTag() !== 'en' ? `/${FJLocalStore.currentTag()}/pricing.html` : '/pricing.html';
    },
    getTeamPricingUri: function () {
        return FJLocalStore.currentTag() !== 'en'
            ? `/${FJLocalStore.currentTag()}/team/pricing.html`
            : '/team/pricing.html';
    },
    getCookie: function (name) {
        let matches = document.cookie.match(
            new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
        );
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    calculateTextDimensions: function (text, fixedWidth, font) {
        // Create an off-screen DOM element
        const offScreenElement = document.createElement('div');

        // Set the element's position, width, and font properties
        offScreenElement.style.position = 'absolute';
        offScreenElement.style.opacity = '0';
        offScreenElement.style.width = fixedWidth + 'px';
        offScreenElement.style.font = font;

        // Add the text to the element
        const words = text.split(' ');
        words.forEach((word, index) => {
            const span = document.createElement('span');
            span.textContent = word + (index !== words.length - 1 ? ' ' : '');
            offScreenElement.appendChild(span);
        });

        // Append the element to the DOM
        document.body.appendChild(offScreenElement);

        let maxWidth = 0;
        let lineWidth = 0;
        let prevSpanRight = 0;

        // Calculate the maximum line width
        offScreenElement.childNodes.forEach((span, index) => {
            const spanRect = span.getBoundingClientRect();
            if (index === 0) {
                lineWidth = spanRect.width;
            } else {
                if (spanRect.left >= prevSpanRight) {
                    lineWidth += spanRect.width;
                } else {
                    maxWidth = Math.max(maxWidth, lineWidth);
                    lineWidth = spanRect.width;
                }
            }
            prevSpanRight = spanRect.right;
        });
        maxWidth = Math.max(maxWidth, lineWidth);

        // Measure the dimensions of the element
        const dimensions = {
            width: maxWidth,
            height: offScreenElement.clientHeight,
        };

        // Remove the element from the DOM
        document.body.removeChild(offScreenElement);

        return dimensions;
    },

    formatNumber: function (value) {
        const currentTag = FJLocalStore.currentTag();
        if (currentTag === 'cn' || currentTag === 'tw' || currentTag === 'jp') {
            if (value < 10000) {
                return value;
            } else {
                let unit = '';
                if (currentTag === 'cn' || currentTag === 'jp') {
                    unit = '万';
                } else if (currentTag === 'tw') {
                    unit = '萬';
                }
                return value / 10000 + unit;
            }
        } else {
            if (value < 10000) {
                return value;
            } else if (value < 1000000) {
                return value / 1000 + 'k';
            } else {
                return value / 1000000 + 'm';
            }
        }
    },

    packageName: function (userPackage) {
        switch (userPackage) {
            case 'free':
                return FJLocalStore._('FREE_NAME');
            case 'basic':
                return FJLocalStore._('BASIC_NAME');
            case 'plus':
                return FJLocalStore._('PLUS_NAME');
            case 'business':
                return FJLocalStore._('BUSINESS_NAME');
            case 'team':
                return FJLocalStore._('TEAM_NAME');
            default:
        }
    },

    /**
     * 检查登录弹出弹窗
     * @param {*} code
     */
    checkLogin(code) {
        let hintText = '';
        const email = window?.userInfo?.userEmail;
        if (code === 214) {
            hintText = FJLocalStore._('your-login-has-expired-please-login-again-to-continue');
        } else if (code === 335 || code === 213) {
            hintText = FJLocalStore._('the-login-email-has-been-changed-please-login-again');
        } else return;
        FJMessageStore.addMessage(hintText, 'error');
        window?.PanelFunc?.showSignUpPane && window.PanelFunc.showSignUpPane(email);
    },

    /**
     * 跳转到编辑页
     * @param params 传递进来用于拼接的跳转参数
     * @param openInNewWindow
     */
    goToEditorApp: function (params = '', openInNewWindow = false) {
        const url = this.getCurrentUrl() + '/editor/app' + (typeof params === 'string' ? params : '');
        if (openInNewWindow) {
            window.open(url);
        } else {
            window.location.href = url;
        }
    },

    /**
     * 精准计算多个浮点数相乘
     * @param numbers 数字组成的数组
     */
    preciseMultiply: function (numbers) {
        // 剔除不是数字的元素
        const filterNumbers = numbers.filter(item => typeof item === 'number');
        if (filterNumbers.length === 0) {
            return 0; // 没有输入数时返回 0
        }

        // 计算所有浮点数的小数位数总和
        let totalDecimalPlaces = 0;
        const scaledNumbers = filterNumbers.map(number => {
            const decimalPlaces = (number.toString().split('.')[1] || '').length;
            totalDecimalPlaces += decimalPlaces;
            return Number(number.toString().replace('.', ''));
        });

        // 将放大的整数相乘
        const multipliedResult = scaledNumbers.reduce((acc, curr) => acc * curr, 1);

        // 将结果缩小回去
        return multipliedResult / Math.pow(10, totalDecimalPlaces);
    },

    /**
     * @description 格式化数字为千分位
     * @param {number | string} number
     * @returns
     */
    formatNumberThousandth: function (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    /**
     * 获取a/b测试客户端身份
     * @return string 返回A或者B
     */
    getAB: function () {
        let id = window.localStorage.getItem('AB-ID');
        if (!id) {
            id = window.Math.floor(window.Math.random() * 10000) % 2 ? 'A' : 'B';
            window.localStorage.setItem('AB-ID', id);
        }

        return id;
    },

    getPayCur: function () {
        let payCur = 'o-usd';
        if (window.fj.exchangeRate.should_use_currency) {
            // 登录了
            if (FJConfig.eurList.indexOf(window.fj.exchangeRate.area_code) !== -1) {
                if (window.fj.exchangeRate.should_use_currency.can_ab_test) {
                    payCur = this.getAB() === 'B' ? 'eu-eur' : 'eu-usd';
                } else {
                    payCur = window.fj.exchangeRate.should_use_currency.code === 'EUR' ? 'eu-eur' : 'eu-usd';
                }
            } else {
                payCur = window.fj.exchangeRate.should_use_currency.code === 'EUR' ? 'eu-eur' : 'o-usd';
            }
        } else {
            if (window.fj.exchangeRate.should_use_currency.can_ab_test) {
                payCur = this.getAB() === 'B' ? 'eu-eur' : 'eu-usd';
            }
        }
        return payCur;
    },
    checkIsLogin: function () {
        if (window.fjuser && !window.fjuser.isUserLogin()) {
            window.fjuser.addListener(
                window.fjuser.eventType.login | window.fjuser.eventType.register,
                (result, json) => {
                    if (result) {
                        window.FJGlobalariable.modSubscription.showLoading = true;
                        window.FJGlobalariable.modSubscription.network
                            .getSubscription()
                            .then(response => {
                                window.FJGlobalariable.modSubscription.updateSubscriptionInfo(
                                    response.data.subscription,
                                );
                            })
                            .catch(error => {})
                            .finally(() => {
                                window.FJGlobalariable.modSubscription.showLoading = false;
                            });
                    }
                },
                true,
            );
            window.fjuser.showLoginPanel();
            return false;
        } else {
            return true;
        }
    },
    checkIsLoginByNetwork: async function () {
        try {
            const isLogin = await FJNetworkStore.checkIsLogin();
            if (!isLogin) {
                window.fjuser.showLoginPanel();
            }
            return isLogin;
        } catch (error) {
            FJMessageStore.addMessage(FJLocalStore._('network-error-please-try-again-later'), 'error');
            return false;
        }
    },
};

// 显式绑定 this
Util.goToEditorApp = Util.goToEditorApp.bind(Util);
export default Util;
