import Style from './CardInfoBrick.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../reducer/action/mainAction';

let CardInfoBrick = () => {
    let dispatch = useDispatch();
    const { currentPlan, teamSubscription, planType } = useSelector(state => ({
        currentPlan: state.mainReducer.currentPlan,
        teamSubscription: state.mainReducer.teamSubscription,
        planType: state.mainReducer.planType,
    }));
    let lastNum = () => {
        if (planType === 'team' && teamSubscription?.last4) {
            return '***********' + teamSubscription.last4;
        }

        if (currentPlan?.last4) {
            return '***********' + currentPlan.last4;
        }

        return '***********1234';
    };
    let _click = () => {
        dispatch(mainAction.openEditCardInfoPopup());
    };
    return (
        <div className={Style.box}>
            <div className={Style.title_card}>
                <span className={Style.title_box}>{FJLocalStore._('PAY_CARD_NUMBER')}</span>
                <span className={Style.btn} onClick={_click}>
                    {FJLocalStore._('CHANGE_CARD')}
                </span>
            </div>
            <div className={Style.content}>{lastNum()}</div>
        </div>
    );
};
export default CardInfoBrick;
