const AUTO_SUBTITLE_PACKAGE_QUOTA = {
    free: 5,
    basic: 30,
    plus: 60,
    business: 240,
    team: 240,
};
const BACKGROUND_REMOVAL_PACKAGE_QUOTA = {
    free: 3,
    basic: 25,
    plus: 100,
    business: 500,
    team: 500,
};

export { AUTO_SUBTITLE_PACKAGE_QUOTA, BACKGROUND_REMOVAL_PACKAGE_QUOTA };
