import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import React, { useImperativeHandle, forwardRef, useEffect } from 'react';
import FJMessageStore from '../../../store/FJMessageStore';
import FJLocalStore from '../../../store/FJLocalStore';
import FJViewConstant from '../../../constant/FJViewConstant';
import { useAmount } from '../../../hooks/useAmount';
const FJCheckoutForm = (props, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const { stripePay, changeLoadingStatus, loadStripeUIStatus } = props;

    const amount = useAmount();

    useEffect(() => {
        if (amount && elements) {
            elements.update({ amount: amount });
        }
    }, [amount, elements]);

    const handleSubmit = async (planData, options = {}) => {
        // event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        try {
            const { error: submitError } = await elements.submit();

            if (submitError) {
                console.log('submitError::', submitError);
                FJMessageStore.addMessage(submitError.message, 'error');
                return;
            }

            changeLoadingStatus(true);

            // const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
            //     elements,
            // });
            const paymentMethod = null;

            const { error, confirmationToken } = await stripe.createConfirmationToken({
                elements,
            });

            if (error) {
                if (error.type === 'card_error' || error.type === 'validation_error') {
                    FJMessageStore.addMessage(error.message, 'error');
                } else {
                    FJMessageStore.addMessage(error.message, 'error');
                }
                // This point is only reached if there's an immediate error when
                // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
                changeLoadingStatus(false);
                return;
            }
            stripePay(confirmationToken, { ...options, planData, paymentMethod });
        } catch (e) {
            FJMessageStore.addMessage(FJLocalStore._('network-error-please-try-again-later'), 'error');
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                handleSubmit,
            };
        },
        [handleSubmit],
    );

    const _onLoaderStart = () => {
        loadStripeUIStatus(FJViewConstant.START);
    };

    const _onReady = () => {
        loadStripeUIStatus(FJViewConstant.SUCCESS);
    };

    const _onLoadError = () => {
        loadStripeUIStatus(FJViewConstant.ERROR);
    };

    return (
        <form>
            <PaymentElement onLoaderStart={_onLoaderStart} onReady={_onReady} onLoadError={_onLoadError} />
        </form>
    );
};

export default forwardRef(FJCheckoutForm);
