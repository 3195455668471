import styles from './FJPaySuccess.module.less';
import FJLocalStore from '../../../store/FJLocalStore';
import InlineSvg from 'react-inlinesvg';
import PaySuccessSvg from '../../../icon/pay-success.svg';
import PaySuccessBgSvg from '../../../icon/pay-success-bg.svg';
import CloseSvg from '../../../icon/mobile-close.svg';
import { useAmount } from '../../../hooks/useAmount';
import FJMobileFooter from '../FJMobileFooter/FJMobileFooter';

const FJPaySuccess = props => {
    const { creditsPayData, currentCountry, closePanel, startNow } = props;
    const payInfo = creditsPayData.info;
    const amount = useAmount();
    const price = (amount / 100).toFixed(2);

    return (
        <div className={styles.box}>
            <div className={styles.close_btn} onClick={closePanel}>
                <InlineSvg src={CloseSvg} />
            </div>
            <div className={styles.main}>
                <InlineSvg className={styles.background} src={PaySuccessBgSvg} />
                <InlineSvg src={PaySuccessSvg} />
                <div className={styles.title}> {FJLocalStore._('payment-successful')}</div>
                <div className={styles.desc}>{currentCountry + payInfo?.priceUnit + price}</div>
            </div>

            <FJMobileFooter btnClassName={styles.pay_btn} text={FJLocalStore._('start-now')} onClick={startNow} />
        </div>
    );
};
export default FJPaySuccess;
