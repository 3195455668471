import Style from './HeadBrick.module.less';
import InlineSVG from 'react-inlinesvg';
import arrowIcon from '../../icon/back.svg';
import FJLocalStore from '../../store/FJLocalStore';
import CloseButton from '../common/CloseButton/CloseButton';
import classNames from 'classnames';

let HeadBrick = props => {
    let { arrowClick, closeClick, style, classname, popupType } = props;
    return (
        <div className={classNames(Style.header_box, classname)} style={style}>
            <div className={classNames(Style.back_box, { [Style.back_box_show]: arrowClick })} onClick={arrowClick}>
                <div className={Style.back_icon}>
                    <InlineSVG src={arrowIcon} />
                </div>
                <span>{FJLocalStore._('BUTTON_BACK')}</span>
            </div>
            {popupType ? <CloseButton onClick={closeClick} className={Style.close} /> : null}
        </div>
    );
};
export default HeadBrick;
