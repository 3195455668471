import styles from './FJAIQuota.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import { useRef } from 'react';
import FJViewConstant from '../../constant/FJViewConstant';
import FJUtil from '../../util/FJUtil';
import { AI_LIMIT } from '../../config/FJPlanData';

const FJAIQuota = props => {
    const { userPackage, isAnnually, planType } = props;
    // isAnnually 是否包年
    const list = useRef([
        {
            text: 'ai-text-to-video',
            monthLimit: 'videos-mo',
            yearLimit: 'videos-yr',
            type: FJViewConstant.AI_VIDEO_LIMIT,
        },
        {
            text: 'ai-text-to-images',
            monthLimit: 'generations-mo',
            yearLimit: 'generations-yr',
            type: FJViewConstant.AI_IMAGE_LIMIT,
        },
        {
            text: 'text-to-speech',
            monthLimit: 'characters-mo',
            yearLimit: 'characters-yr',
            type: FJViewConstant.TEXT_TO_SPEECH_LIMIT,
        },
        {
            text: 'ai-script',
            monthLimit: 'scripts-mo',
            yearLimit: 'scripts-yr',
            type: FJViewConstant.AI_SCRIPT_LIMIT,
        },
        {
            text: 'auto-subtitle',
            monthLimit: 'mins-mo',
            yearLimit: 'mins-yr',
            type: FJViewConstant.SUBTITLE_LIMIT,
        },
        {
            text: 'remove-background',
            monthLimit: 'credits-mo',
            yearLimit: 'credits-yr',
            type: FJViewConstant.REMOVE_BACKGROUND_LIMIT,
        },
        {
            text: 'translate',
            monthLimit: 'characters-mo',
            yearLimit: 'characters-yr',
            type: FJViewConstant.TRANSLATE_LIMIT,
        },
        {
            text: 'audio-reduce',
            monthLimit: 'mins-mo',
            yearLimit: 'mins-yr',
            type: FJViewConstant.AUDIO_REDUCE_LIMIT,
        },
        {
            text: 'vocal-remover',
            monthLimit: 'mins-mo',
            yearLimit: 'mins-yr',
            type: FJViewConstant.VOCAL_REMOVER_LIMIT,
        },
    ]);

    return (
        <div className={styles.box}>
            <div className={styles.title}>
                {(planType === 'team'
                    ? FJLocalStore._('ai-quota-per-member-includes')
                    : FJLocalStore._('ai-quota-includes')
                ).replace('xxx', FJUtil.packageName(userPackage))}
            </div>
            <ul className={styles.list}>
                {list.current.map((item, index) => {
                    return (
                        <li key={index}>
                            <div className={styles.left}>{FJLocalStore._(item.text)}</div>
                            <div className={styles.line} />
                            <div className={styles.right}>
                                {FJUtil.formatNumber(
                                    AI_LIMIT[item.type][userPackage] * (isAnnually && userPackage !== 'free' ? 12 : 1),
                                )}
                                &nbsp;
                                {FJLocalStore._(
                                    isAnnually && userPackage !== 'free' ? item.yearLimit : item.monthLimit,
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
export default FJAIQuota;
