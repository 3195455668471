import Style from './PayMethod.module.less';
import InlineSVG from 'react-inlinesvg';
import cardIcon from '../icon/credit_card.svg';
import paypalIcon from '../icon/paypal.svg';
import FJLocalStore from '../store/FJLocalStore';
import classNames from 'classnames';

const PayMethod = props => {
    let { method, change } = props;
    return (
        <div className={Style.box}>
            <div
                className={Style.select_box}
                style={{
                    left: method === 'paypal' ? '50%' : '4px',
                }}
            ></div>
            <div
                onClick={() => {
                    change('stripe');
                }}
                className={classNames(Style.btn_box, { [Style.active]: method !== 'paypal' })}
            >
                <InlineSVG src={cardIcon} />
                <span className={Style.text}>{FJLocalStore._('CREDIT_CARD')}</span>
            </div>
            <div
                onClick={() => {
                    change('paypal');
                }}
                className={classNames(Style.btn_box, { [Style.active]: method === 'paypal' })}
            >
                <InlineSVG src={paypalIcon} />
                <span className={Style.text}>Paypal</span>
            </div>
        </div>
    );
};
export default PayMethod;
