import React, { useState } from 'react';
import HeadBrick from '../HeadBrick/HeadBrick';
import Style from './EndPanel.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import successIcon from '../../icon/success-new.svg';
import AnWerSvg from '../../icon/anwer.svg';
import InlineSVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../reducer/action/mainAction';
import { useEffect } from 'react';
import classNames from 'classnames';
import FJUtil from '../../util/FJUtil';

let EndPanel = props => {
    let { isAnnually } = props;
    let dispatch = useDispatch();
    let { method, dataInfo, currentPlan, selectPage, teamSubscription } = useSelector(state => {
        return {
            method: state.mainReducer.method,
            dataInfo: state.mainReducer.dataInfo,
            currentPlan: state.mainReducer.currentPlan,
            selectPage: state.mainReducer.selectPage,
            teamSubscription: state.mainReducer.teamSubscription,
        };
    });
    let _close = () => {
        dispatch(mainAction.closeSubscribeView());
    };

    let timestampToDate = timestamp => {
        let map = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let date = new Date(timestamp * 1000);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();
        return map[month] + ' ' + day + ', ' + year;
    };

    let price = () => {
        return dataInfo.amount / 100;
    };
    let endDate = () => {
        return selectPage === 'team'
            ? timestampToDate(teamSubscription.period_end)
            : timestampToDate(currentPlan.period_end);
    };
    let _descText = () => {
        let text = FJLocalStore._('SUCCESSFUL_DESC');
        text = text.replace('%s', price());
        text = text.replace('%s', endDate());
        return text;
    };
    let showImage = () => {
        let amount = dataInfo.amount / 100;
        return amount > 0;
    };
    let affiliateURL = () => {
        let coupon = dataInfo.coupon;
        let discount = dataInfo.discount;
        let amount = dataInfo.amount / 100;
        let transactionID = dataInfo.transaction_id;

        if (!transactionID) transactionID = window.fjuser.info.id;
        let url = null;

        if (amount > 0) {
            if (document.domain === 'www.designcap.com' || document.domain === 'designcap.com') {
                url =
                    'https://shareasale.com/sale.cfm?amount=' +
                    amount +
                    '&tracking=' +
                    transactionID +
                    '&transtype=sale&merchantID=79751&storeid=3';
                if (coupon && coupon !== '') {
                    url += '&couponcode=' + coupon;
                }
            } else if (document.domain === 'www.flexclip.com' || document.domain === 'flexclip.com') {
                url =
                    'https://shareasale.com/sale.cfm?amount=' +
                    amount +
                    '&tracking=' +
                    transactionID +
                    '&transtype=sale&merchantID=79751&storeid=1';
                if (coupon && coupon !== '') {
                    url += '&couponcode=' + coupon;
                }

                try {
                    let packageName = selectPage;
                    let period = isAnnually ? 'annual' : 'monthly';
                    // GA4电子商务
                    // gtag('event', 'purchase', {
                    //     "transaction_id": transactionID,
                    //     "affiliation": "",
                    //     "value": amount,
                    //     "currency": "USD",
                    //     "tax": 0,
                    //     "shipping": 0,
                    //     "items": [
                    //         {
                    //             "item_id": packageName,
                    //             "item_name": packageName,
                    //             "list_name": packageName,
                    //             "item_brand": "FlexClip",
                    //             "category": period,
                    //             "variant": period,
                    //             "list_position": 1,
                    //             "quantity": 1,
                    //             "price": amount
                    //         }
                    //     ],
                    //     "send_to": ["G-R5WHLT8DF7", "AW-974294121/d47OCPHyrYAYEOmYytAD"]
                    // });

                    window.gtag('event', 'conversion', {
                        send_to: 'AW-974294121/8eefCP7Z8LAYEOmYytAD',
                        value: amount,
                        currency: 'USD',
                        transaction_id: transactionID,
                    });

                    window.gtag('event', 'conversion', {
                        send_to: 'AW-974294121/aV0FCM2DhK4YEOmYytAD',
                        value: amount,
                        currency: 'USD',
                        transaction_id: transactionID,
                    });

                    // GA4电子商务
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push({
                        event: 'purchase',
                        ecommerce: {
                            transaction_id: transactionID,
                            affiliation: '',
                            value: amount,
                            currency: 'USD',
                            tax: 0,
                            shipping: 0,
                            items: [
                                {
                                    item_id: packageName,
                                    item_name: packageName,
                                    list_name: packageName,
                                    item_brand: 'FlexClip',
                                    category: period,
                                    variant: period,
                                    list_position: 1,
                                    quantity: 1,
                                    price: amount,
                                },
                            ],
                            send_to: ['G-R5WHLT8DF7', 'AW-974294121/d47OCPHyrYAYEOmYytAD'],
                        },
                    });
                    console.log('purchase');
                } catch (e) {}
            }

            if (window.affiliateStatistics) {
                window.affiliateStatistics({ transactionID, amount, coupon, discount, type: 'subscription' });
                window.affiliateStatistics = null;
            }
        }

        return url;
    };

    /**
     * 增加订阅成功统计事件
     */
    useEffect(() => {
        if (window.eventLog) {
            window.eventLog.addEvent('revenue', {
                price: window.FJGlobalariable.modSubscription.state.amount / 100,
                plan: `${window.FJGlobalariable.modSubscription.state.selectPlan.package}_${
                    window.FJGlobalariable.modSubscription.state.isAnnual ? 'annual' : 'monthly'
                }`,
                new_renew: window.FJGlobalariable.modSubscription.state.selectPlan.isPopular ? 'renew' : 'new',
                platform: window.fj.currentPlan.sub_type,
                coupon: window.FJGlobalariable.modSubscription.state.coupon,
            });

            FJUtil.zgEventLog('支付页面-支付成功', {
                价格: window.FJGlobalariable.modSubscription.state.amount / 100,
                订阅类型: window.FJGlobalariable.modSubscription.state.selectPlan.isPopular ? '续订' : '新订阅',
                套餐类型: `${selectPage}_${isAnnually ? 'annual' : 'monthly'}`,
                支付平台: window.fj.currentPlan.sub_type,
                折扣码: dataInfo.coupon,
            });
        }
    }, []);

    const [items, setItems] = useState([
        { id: 1, content: FJLocalStore._('questionnaire-survey-list-1'), selected: false },
        { id: 2, content: FJLocalStore._('questionnaire-survey-list-2'), selected: false },
        { id: 3, content: FJLocalStore._('questionnaire-survey-list-3'), selected: false },
        { id: 4, content: FJLocalStore._('questionnaire-survey-list-4'), selected: false },
        { id: 5, content: FJLocalStore._('questionnaire-survey-list-5'), selected: false },
        { id: 6, content: FJLocalStore._('questionnaire-survey-list-6'), selected: false },
        { id: 7, content: FJLocalStore._('questionnaire-survey-list-7'), selected: false },
        { id: 8, content: FJLocalStore._('questionnaire-survey-list-8'), selected: false },
    ]);

    const checkQuestionnaire = id => {
        setItems(prevItems => {
            return prevItems.map(item => {
                if (item.id === id) {
                    return { ...item, selected: !item.selected };
                } else {
                    return item;
                }
            });
        });
    };

    const _commit = () => {
        let trueItemsId = items.filter(item => item.selected).map(item => item.id);
        let item = {
            'questionnaire-survey-list-1': 'Export videos without a watermark.',
            'questionnaire-survey-list-2': 'Get more Cloud Space.',
            'questionnaire-survey-list-3': 'Export 1080p (full HD) videos.',
            'questionnaire-survey-list-4': 'Save more video projects.',
            'questionnaire-survey-list-5': 'Get more AI function credits.',
            'questionnaire-survey-list-6': 'Make longer videos.',
            'questionnaire-survey-list-7': 'Get more royalty-free videos and audio.',
            'questionnaire-survey-list-8': 'Upload local fonts/brandings.',
        };
        for (let i = 0; i < trueItemsId.length; i++) {
            window.ealog &&
                window.ealog.addEvent('pay success page Payment reason option', {
                    val: item[`questionnaire-survey-list-${trueItemsId[i]}`],
                });
        }
        if (method === 'popup') {
            dispatch(mainAction.closeSubscribeView());
        } else {
            window.location.href = FJLocalStore.getJumpUrl('/editor/');
        }
    };

    return (
        <div className={Style.success_box}>
            <HeadBrick
                classname={Style.head}
                style={method === 'popup' ? {} : { display: 'none' }}
                closeClick={_close}
            />
            <div className={Style.successful_content_box}>
                <div className={Style.icon}>
                    <InlineSVG src={successIcon} />
                </div>
                <div className={Style.title}>{FJLocalStore._('questionnaire-survey-list-title')}</div>
                <p className={Style.info}>{_descText()}</p>
            </div>
            <div className={Style.questionnaire}>
                <div className={Style.questionnaire_t}>{FJLocalStore._('questionnaire-survey-list-info')}</div>
                <div className={Style.questionnaire_lists}>
                    {items.map(item => {
                        return (
                            <div
                                key={item.id}
                                className={classNames(Style.questionnaire_list, { [Style.selected]: item.selected })}
                                onClick={() => {
                                    checkQuestionnaire(item.id);
                                }}
                            >
                                <div className={Style.checkbox}>
                                    {item.selected ? <InlineSVG src={AnWerSvg} /> : ''}
                                </div>
                                <div className={Style.questionnaire_list_c}>{item.content}</div>
                            </div>
                        );
                    })}
                </div>

                <div
                    className={Style.button}
                    onClick={() => {
                        _commit();
                    }}
                >
                    {FJLocalStore._('BUTTON_GET_START_1')}
                </div>
                {showImage() && <img src={affiliateURL()} width='1' height='1' alt='' />}
            </div>
        </div>
    );
};
export default EndPanel;
