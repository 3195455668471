/**
 * @description 全局消息提示单例
 */
let FJMessageStore = (function () {
    let _disabled = false;

    const getMessage = () => {
        return (
            window.fjmessage || {
                addMessage: function (content, type = 'message', duration = 3) {
                    console.log(content, type, duration);
                },
                flush: function () {
                    // console.log('flush message');
                },
            }
        );
    };

    return {
        /**
         *
         * @param content 消息内容
         * @param type 消息类型，message，warning，error
         * @param duration 持续时长，单位秒
         */
        addMessage(content, type = 'message', duration = 6) {
            if (!_disabled) {
                getMessage().addMessage(content, type, duration);
            }
        },

        /**
         * 清空当前所有flash消息
         */
        flush() {
            getMessage().flush();
        },

        enable() {
            _disabled = false;
        },

        disable() {
            _disabled = true;
        },
    };
})();

export default FJMessageStore;
