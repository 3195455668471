import React, { Component } from 'react';
import FJLocalStore from '../../store/FJLocalStore';
import classNames from 'classnames';
import InlineSvg from 'react-inlinesvg';
import minusIcon from '../../icon/decrease.svg';
import Style from './FJQuestionBrick.module.less';
import FJUtil from '../../util/FJUtil';

class QuestionBrick extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curActionIndex: -1,
            actionQuestion: '',
            actionQuestionList: [],
        };
    }
    selectActionIndex = question => {
        let actionQuestionList = this.state.actionQuestionList;
        let index = actionQuestionList.indexOf(question);
        if (index === -1) {
            actionQuestionList.push(question);
        } else {
            actionQuestionList.splice(index, 1);
        }
        this.setState({
            actionQuestionList: actionQuestionList,
        });
    };

    drawList = () => {
        let { actionQuestionList } = this.state;
        let { questionList, replaceUrl, itemClass = '' } = this.props;
        let URL = replaceUrl ? replaceUrl : FJUtil.getCurrentUrl() + '/tools/convert-video/';

        return questionList.map((item, index) => {
            let active = actionQuestionList.indexOf(item.title) !== -1;
            return (
                <div
                    key={index}
                    className={classNames(Style.itemSelf, itemClass)}
                    onClick={() => {
                        this.selectActionIndex(item.title);
                    }}
                >
                    <div className={classNames(Style.itemTitle)}>
                        <h3 className={Style.title}>{FJLocalStore._(item.title)}</h3>
                        <InlineSvg
                            className={classNames(Style.itemIcon, { [Style.iconActive]: active })}
                            width='14px'
                            height='14px'
                            alt='Minus'
                            loading='lazy'
                            src={minusIcon}
                            element='span'
                        />
                        <img
                            className={classNames(Style.itemIcon, { [Style.iconActive]: !active })}
                            src='https://resource.flexclip.com/pages/common/icon-add1.png'
                            alt='minus'
                            width={14}
                            height={14}
                            loading={'lazy'}
                        />
                    </div>
                    <p
                        className={classNames(Style.itemDisplay, active ? Style.itemDisplayActive : '')}
                        dangerouslySetInnerHTML={{
                            __html: FJLocalStore._(item.answer).replace('xxx', URL),
                        }}
                    >
                        {/*{FJLocalStore._(item.answer)}*/}
                    </p>
                    {/*<div className={Style.line} />*/}
                </div>
            );
        });
    };
    render() {
        let { boxStyle = {}, boxClass = '', direction = 'horizontal' } = this.props;

        // horizontal and vertical 改变布局方向，默认为左右布局
        const directionStyle = direction !== 'horizontal' ? Style.contentVertical : '';

        return (
            <div className={classNames(Style.box, boxClass)} style={boxStyle}>
                <div className={classNames(Style.content, directionStyle)}>
                    <h2 className={classNames(Style.leftContent)}>{FJLocalStore._('frequently-asked-questions')}</h2>
                    <div className={classNames(Style.rightContent)}>{this.drawList()}</div>
                </div>
            </div>
        );
    }
}
export default QuestionBrick;
