export default {
    SHOW_SUBSCRIBE_VIEW: 'SHOW_SUBSCRIBE_VIEW',
    CLOSE_SUBSCRIBE_VIEW: 'CLOSE_SUBSCRIBE_VIEW',
    // SET_OPEN_METHOD:'SET_OPEN_METHOD',
    OPEN_CARD_INFO_EDIT_POPUP: 'OPEN_CARD_INFO_EDIT_POPUP',
    CLOSE_CARD_INFO_EDIT_POPUP: 'CLOSE_CARD_INFO_EDIT_POPUP',
    CHANGE_PAY_METHOD: 'CHANGE_PAY_METHOD',
    CHANGE_CURRENT_STEP: 'CHANGE_CURRENT_STEP',
    CHANGE_SELECT_PAGE: 'CHANGE_SELECT_PAGE',
    CHANGE_LOADING_STATUS: 'CHANGE_LOADING_STATUS',
    SET_CURRENT_PLAN: 'SET_CURRENT_PLAN',
    SET_DATA_INFO: 'SET_DATA_INFO',
    SET_DISCOUNT_CONF: 'SET_DISCOUNT_CONF',
    SET_CURRENT_DISCOUNT_DATA: 'SET_CURRENT_DISCOUNT_DATA',
    SET_PAYPAL_SDK_STATUS: 'SET_PAYPAL_SDK_STATUS',
    SET_TEAM_SUBSCRIPTION: 'SET_TEAM_INFO',
    UPDATE_SEAT_COUNT: 'UPDATE_SEAT_COUNT',
    UPDATE_IS_ANNUALLY: 'UPDATE_IS_ANNUALLY',

    CHANGE_TOP_PANEL: 'CHANGE_TOP_PANEL',
    TO_CREDITS_PAY_PANEL: 'TO_CREDITS_PAY_PANEL',
    OPEN_OR_CLOSE_CREDITS_PAY_PANEL: 'OPEN_OR_CLOSE_CREDITS_PAY_PANEL',
    SHOW_OR_HIDDEN_PANEL_BUTTON: 'SHOW_OR_HIDDEN_PANEL_BUTTON',
    COPY_PLAN_BUTTON_STATUS: 'COPY_PLAN_BUTTON_STATUS',
    CHANGE_CHECKED_CREDITS: 'CHANGE_CHECKED_CREDITS',
    CHANGE_BUY_CREDITS_SUCCESS_PAGE: 'CHANGE_BUY_CREDITS_SUCCESS_PAGE',

    SET_SEAT_COUNT: 'SET_SEAT_COUNT',

    SET_CURRENT_COUNTRY: 'SET_CURRENT_COUNTRY',
    SET_CURRENT_COUNTRY_MONETARY: 'SET_CURRENT_COUNTRY_MONETARY',
    SET_CURRENT_COUNTRY_MONETARY_ISO: 'SET_CURRENT_COUNTRY_MONETARY_ISO',
    SET_EXCHANGE_RATE: 'SET_EXCHANGE_RATE',

    CHANGE_LOAD_STRIPE_UI_STATUS: 'CHANGE_LOAD_STRIPE_UI_STATUS',
    SET_AMOUNT: 'SET_AMOUNT',
    SET_CREDITS_NUM: 'SET_CREDITS_NUM',
    SET_MOBILE_PAY_STEP: 'SET_MOBILE_PAY_STEP',
};
